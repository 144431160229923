import { Injectable, Injector } from "@angular/core";
import { ShortKeyService } from "./short-key.service";



declare const $: any;

@Injectable({
  providedIn: "root",
})
export class SuggestionService {

  public ws
  public wsaddword;
  public word_suggestion: any;

  constructor(
    private shortKeyService: ShortKeyService) {
  }


  connectSuggestion(host, port) {
    this.ws = new WebSocket("wss://" + host + ":" + port);

    this.ws.onopen = () => {
      console.debug('Suggestion is connected');
    };

    this.ws.onclose = (e) => {
      const log = 'disconnected from server';
      console.debug(log);
    };

    this.ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (this.shortKeyService.searchText == "") {
        if (data.suggestions[0]) {
          this.shortKeyService.searchPasteboard(data.key);
        } else {
          this.shortKeyService.resetPasteBoard()
        }
      }

      this.word_suggestion = [
        {
          Label: 'Insert',
          Tag: data.suggestions.length >= 1 ? data.suggestions[0].Tag : -1,
          Key: data.suggestions.length >= 1 ? data.key : '',
          Value: data.suggestions.length >= 1 ? data.suggestions[0].Value : '',
        },
        {
          Label: 'Home',
          Tag: data.suggestions.length >= 2 ? data.suggestions[1].Tag : -1,
          Key: data.suggestions.length >= 2 ? data.key : '',
          Value: data.suggestions.length >= 2 ? data.suggestions[1].Value : '',
        },
        {
          Label: 'PgUp',
          Tag: data.suggestions.length >= 3 ? data.suggestions[2].Tag : -1,
          Key: data.suggestions.length >= 3 ? data.key : '',
          Value: data.suggestions.length >= 3 ? data.suggestions[2].Value : '',
        }
      ];
    };
  }

  setWordSuggest() {
    this.word_suggestion = [
      {
        Label: 'Insert',
        Tag: -1,
        key: '',
        Value: '',
      },
      {
        Label: 'Home',
        Tag: -1,
        key: '',
        Value: '',
      },
      {
        Label: 'PgUp',
        Tag: -1,
        key: '',
        Value: '',
      }
    ];
  }

  sendSuggestText(text, tags) {
    let data;
    if (tags !== -1) {
      data = {
        tag: tags,
        isComplete: false,
        msg: text,
      };
    } else {
      data = {
        isComplete: true,
      };
    }
    this.ws.send(JSON.stringify(data));
  }

  addword(host, port) {

    this.wsaddword = new WebSocket("wss://" + host + ":" + port + "/addword");

    this.wsaddword.onopen = () => {
      console.debug('Add word is connected');
    };

    this.wsaddword.onclose = (e) => {
      const log = 'disconnected from server';
      console.debug(log);
      console.log(e);
      setTimeout(() => {
        this.addword(host, port)
      }, 3000)

    };
    this.wsaddword.onerror = (event) => {
    };


    this.wsaddword.onmessage = (e) => {
    }
  }
}