<nav class="navbar navbar-dark bg-dark">
  <form class="form-inline">
    <label>
      <span class="navbar-brand mb-0 h1">({{room.id}}) {{room.title}}</span>
    </label>
  </form>
  <span class="navbar-brand mb-0 h1 out" (click)="back()">สิ้นสุดการถอดความ</span>
</nav>

<div class="row" style="margin-right: 0 !important">
  <div class="col-2">
    <div class="row" style="padding-left: 1vw; padding-top: 0.5vw">
      <div class="col-12">
        <video id="video" height="100%" width="100%" autoplay muted controls></video>
        <audio id="audio" style="display: none;"></audio>
      </div>

    </div>
    <div class="row" style="margin-top: 0.5vw">
      <div class="col-12">
        <div class="short-key">
          <div class="card-body list-body text-left">
            <app-short-key></app-short-key>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-7">
    <br>
    <app-suggestion></app-suggestion>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="progress custom-bg">
              <div class="progress-bar bg-warning" role="progressbar" aria-valuemin="0" aria-valuemax="80" id="Ready">
              </div>
              <div class="progress-bar bg-danger" role="progressbar" id="Ready2"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="comment">ข้อความ:</label>
              <textarea class="form-control" rows="4" name="log" [(ngModel)]="this.shortKeyService.log"
                (keydown.enter)="onEnter();false" (paste)="pasteUrl($event)"
                [disabled]="this.shortKeyService.disabled_text" (click)="disableInput()" id="log"></textarea>
            </div>
          </div>
        </div>
        <br>
        <div class="card">
          <div class="card-header">
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" id="Start"></div>
            </div>
            <audio controls id="beep" hidden>
              <source src="../../../assets/sound/beep-07.wav">
            </audio>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="comment">ข้อความเจ้าหน้าที่คนถัดไป:</label>
              <textarea class="form-control" rows="4" name="log" readonly [(ngModel)]="log_next"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row head-row">
      <div class="col-2">
        <div class="card agent-online" *ngIf="network_connection else offline">
          <div class="card-body text-center" style="
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;">
            <i class="fas fa-user-friends"></i>&nbsp;<font>{{agent_online}} online</font>
          </div>
        </div>
        <ng-template #offline>
          <div class="card agent-offline text-center">
            <div class="card-body" style="
              padding-top: 0.7rem;
              padding-bottom: 0.7rem;">
              <i class="fas fa-user-friends"></i>&nbsp;<font>offline</font>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="col-4">

      </div>
      <div class="col-6 text-right">
        <button class="btn btn-warning head-btn" [class.active]="active_shortkey" (click)="switchTeam()"
          id="btn_shortkey"
          [ngClass]="{'request-swt':switch_team.status === 'request','accept-swt':switch_team.status ==='accept'}">
          <font *ngIf="switch_team.status === ''"><i class="fas fa-user-friends"></i>&nbsp;Switch Team</font>
          <font *ngIf="switch_team.status === 'request'"><i class="fas fa-spinner"></i>&nbsp;Request</font>
          <font *ngIf="switch_team.status === 'accept'"><i class="fas fa-check"></i>&nbsp;Accepted</font>
        </button>&nbsp;
        <button class="btn btn-danger head-btn" [class.active]="active_audio" (click)="refreshSound()" id="btn_sound"
          [disabled]="active_audio"><i class="fas fa-volume-up"></i>&nbsp;Refresh Audio</button>&nbsp;
        <button class="btn btn-success head-btn" [class.active]="active_shortkey" (click)="refreshShortKey()"
          id="btn_shortkey"><i class="fas fa-keyboard"></i>&nbsp;Refresh ShortKey</button>&nbsp;


      </div>


    </div>

  </div>
  <app-paste-borad class="col-3 "></app-paste-borad>


</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ยืนยันการกลับสู่หน้าหลัก</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <button type="button" class="btn btn-outline-dark" (click)="back()">ยืนยัน</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">ยกเลิก</button>
  </div>

</ng-template>