<div class="row" aria-label="ผลลัพธ์การถอดความ">
    <div class="col-12">
      <div class="card card-result-revoice" >
        <label class="result-revoice" aria-live="polite">{{resultRevoice}}</label>
      </div>
        <!-- <textarea class="form-control result-revoice" rows="2" name="log" role="textbox"  aria-live="polite" readonly [ngModel]="resultRevoice"></textarea> -->
       
    </div>
    <!-- <div class="col-12">
        <div class="card">
          <div class="card-header">
            <b>ผลลัพท์การถอดความ</b>
          </div>
          <div class="card-body">
            <div class="row" >
              <div class="col-12">
                <textarea class="form-control result-revoice" rows="4" name="log" readonly [ngModel]="resultRevoice"></textarea>
              </div>
            </div>
       
          </div>
        </div>
      </div> -->
</div>


