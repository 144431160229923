import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable } from 'rxjs';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RevoiceSocketService {
  private socket;
  port = 8008;

  host = '192.168.1.119';
  constructor() { }

  revoiceConnect(): Rx.Subject<MessageEvent> {
    this.socket = io(`ws://${this.host}:${this.port}`, { secure: true, rejectUnauthorized: false });
    const observable = new Observable(observer => {
      this.socket.on('connect', (data) => {
        observer.next({ action: 'connect', value: data });
      });
      this.socket.on('update_status', (data) => {
        observer.next({ action: 'update_status', value: data });
      });
      this.socket.on('request_switching', (data) => {
        observer.next({ action: 'request_switching', value: data });
      });
      this.socket.on('accept_switching', (data) => {
        observer.next({ action: 'accept_switching', value: data });
      });
      return () => {
        this.socket.disconnect();
      };
    });
    const observer = {
      next: (data) => {
        const msg = JSON.parse(data);
        if (msg.action === 'connected') {
          this.socket.emit('connected', JSON.stringify(msg.value));
        } else if (msg.action === 'disconnected') {
          this.socket.emit('disconnected', JSON.stringify(msg.value));
        } else if (msg.action === 'status_updated') {
          this.socket.emit('status_updated', JSON.stringify(msg.value));
        } else if (msg.action === 'agent_statuses_updated') {
          this.socket.emit('agent_statuses_updated', JSON.stringify(msg.value));
        } else if (msg.action === 'switching_response_received') {
          this.socket.emit('switching_response_received', JSON.stringify(msg.value));
        }
      },
    };

    return Rx.Subject.create(observer, observable);
  }
}
