import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable } from 'rxjs';
import * as Rx from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private socket;
  private pc;
  port = 10000;

  host = '';
  constructor() { }

  connect(): Rx.Subject<MessageEvent> {
    this.socket = io(`wss://${this.host}:${this.port}`, { secure: true, rejectUnauthorized: false });
    const observable = new Observable(observer => {
      this.socket.on('readyTime', (data) => {
        observer.next({ action: 'readyTime', value: data });
      });
      this.socket.on('forceOut', (data) => {
        observer.next({ action: 'forceOut', value: data });
      });
      this.socket.on('respondJoinRoom', (data) => {

        observer.next({ action: 'joinRoom', value: data });
      });
      this.socket.on('streamBroadcaster', (data) => {
        observer.next({ action: 'streamBroadcaster', value: data });
      });
      this.socket.on('respondSwitch', (data) => {
        observer.next({ action: 'respondSwitch', value: data });
      });
      return () => {
        this.socket.disconnect();
      };
    });
    const observer = {
      next: (data) => {
        const msg = JSON.parse(data);
        if (msg.action === 'joinRoom') {
          this.socket.emit('joinRoom', JSON.stringify(msg.value));
        } else if (msg.action === 'stream') {
          this.socket.emit('stream', JSON.stringify(msg.value));
        } else if (msg.action === 'switchTeam') {
          this.socket.emit('switchTeam', JSON.stringify(msg.value));
        }
      },
    };

    return Rx.Subject.create(observer, observable);
  }

  setPort(connection) {
    this.host = connection.host;
    this.port = Number(connection.queue_port);
  }


}
