<div class="row head-row">
  <div class="col-4" style="padding-left: 1.5rem !important; padding-right: 1.5rem !important"
    *ngFor="let sug of this.suggestionService.word_suggestion; let i = index;">
    <div class="row" [ngClass]="this.bodySuggest" style="cursor: pointer;" (click)="clickSuggestion(sug)">
      <div class="col-md-3 text-center" [ngClass]="this.headSuggest">
        <font>{{sug.Label}}</font>
      </div>
      <div class="col-md-9 text-left" [ngClass]="this.valueSuggest" id="suggestion" data-toggle="tooltip"
        data-placement="top" title="{{sug.Value}}">
        <div>{{sug.Value}}</div>
      </div>
    </div>
  </div>
</div>