import { Injectable } from "@angular/core";
import {
  WebsocketService,
  BroadcastService,
  AuthService,
  RoomsService,
} from "../services";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
declare const $: any;

@Injectable({
  providedIn: "root",
})
export class ShortKeyService {


  shortkey = {
    F1: null,
    F2: null,
    F3: null,
    F4: null,
    F5: null,
    F6: null,
    F7: null,
    F8: null,
    F9: null,
    F10: null,
    // F11: null,
    // F12: null
  };

  short_key_preview = ["", "", "", "", "", "", "", "", "", ""];
  short_key_active = [];
  public pasteboardItem = [];

  public input_pasteboardItem = "";
  public temp_input_short_key = [];
  public regex_short_key = [];

  public short_key_suggest = ["", "", ""];

  log = "";
  disabled_text = true;
  user;
  option_message = {
    next_message: -1,
    current_message: -1,
  };
  textArea = "";
  location = "";
  word = "";
  open_modal = false;
  search = false;
  searchText = "";
  public mouseStartPosition = 0;
  public mouseEndPosition = 0;


  constructor(
    private bcService: BroadcastService,
    private authService: AuthService,
    private roomService: RoomsService,
    private http: HttpClient
  ) {
    this.user = this.authService.getUser();
  }

  refreshShortKey(roomid) {
    $(document).ready(() => {
      $('[data-toggle="tooltip"]').tooltip("hide");
    });
    this.roomService.getRoomById(roomid).subscribe((items: any) => {
      this.shortkey = {
        F1: items.short_keys[0] || "",
        F2: items.short_keys[1] || "",
        F3: items.short_keys[2] || "",
        F4: items.short_keys[3] || "",
        F5: items.short_keys[4] || "",
        F6: items.short_keys[5] || "",
        F7: items.short_keys[6] || "",
        F8: items.short_keys[7] || "",
        F9: items.short_keys[8] || "",
        F10: items.short_keys[9] || "",
        // F11: items.short_keys[10] || '',
        // F12: items.short_keys[11] || ''
      };
      $(document).ready(() => {
        $('[data-toggle="tooltip"]').tooltip({});
      });
      this.short_key_preview = Object.values(this.shortkey);
      this.short_key_active = Object.values(this.shortkey).map(() => false);
    });
  }

  shortKey(value) {
    this.textArea += value;
  }

  shortkeysPE(id) {
    let shortkey = document.getElementById(id);
    return shortkey.dataset['originalTitle'];
  }

  shortkeysPESG(id) {
    let shortkey;
    let deletekey = "";
    if (id.includes("sk")) {
      shortkey = document.getElementById(id);
      id = shortkey.dataset['originalTitle'];
    }
    if (this.searchText == "") {
      for (let index = 0; index < this.word.length; index++) {
        deletekey += "Ց";
      }
    }

    id = deletekey + id;
    this.searchText = "";
    this.word = "";
    this.resetPasteBoard();
    return id;
  }

  clickshortkeysBP(value) {
    if (!this.disabled_text) {
      this.log += value
      const msg: any = {
        agentId: this.user.id,
        msgId: this.option_message.current_message,
        msg: value,
      };
      this.word = "";
      this.searchText = "";
      this.resetPasteBoard();
      this.bcService.sendMsg(JSON.stringify({ action: "stream", value: msg }));
    }
  }

  functionShortkeysBP(id) {
    if (!this.disabled_text) {
      const elem = document.getElementById(id);
      let value = elem.dataset['originalTitle'];
      this.log += value
      const msg: any = {
        agentId: this.user.id,
        msgId: this.option_message.current_message,
        msg: value,
      };
      this.word = "";
      this.searchText = "";
      this.resetPasteBoard();
      this.bcService.sendMsg(JSON.stringify({ action: "stream", value: msg }));
    }

  }

  pasteboardSuggestBP(id) {
    let value = id;
    if (!this.disabled_text) {
      if (this.word != "" && id != "") {
        if (this.searchText == "") {
          this.log = this.log.slice(0, 0 - this.word.length);
          for (let index = 0; index < this.word.length; index++) {
            const msg: any = {
              agentId: this.user.id,
              msgId: this.option_message.current_message,
              msg: 'Ց'
            };
            this.bcService.sendMsg(JSON.stringify({ action: 'stream', value: msg }));

          }
        }
      }
      this.log += value;
      const msg: any = {
        agentId: this.user.id,
        msgId: this.option_message.current_message,
        msg: value,
      };
      document.getElementById('log').focus();
      this.bcService.sendMsg(JSON.stringify({ action: "stream", value: msg }));
      this.searchText = "";
      this.word = "";
      this.resetPasteBoard();
    }
  }

  searchPasteboard(word) {
    if (this.searchText == "") {
      this.regex_short_key = [];
      this.word = word;
      this.pasteboardItem.forEach((shortkey) => {
        const matches = shortkey.toLowerCase().startsWith(word.toLowerCase());
        if (matches) {
          this.regex_short_key.push(shortkey);
        }
      });

      for (let index = 0; index < this.short_key_suggest.length; index++) {
        if (this.regex_short_key[index]) {
          this.short_key_suggest[index] = this.regex_short_key[index];
        } else {
          this.short_key_suggest[index] = "";
        }
      }
    }
  }

  searchPasteboardFromBar(word) {
    this.regex_short_key = [];

    this.regex_short_key = this.pasteboardItem.filter(pasteboard => pasteboard.toLowerCase().includes(word.toLowerCase()));
    // this.pasteboardItem.forEach((shortkey) => {
    //   const matches = shortkey.toLowerCase().startsWith(word.toLowerCase());
    //   if (matches) {
    //     this.regex_short_key.push(shortkey);
    //   }
    // });
    for (let index = 0; index < this.short_key_suggest.length; index++) {
      if (this.regex_short_key[index]) {
        this.short_key_suggest[index] = this.regex_short_key[index];
      } else {
        this.short_key_suggest[index] = "";
      }
    }
  }

  resetPasteBoard() {
    this.short_key_suggest = ["", "", ""];
  }

  beep() {
    const audio = new Audio();
    audio.src = "../../assets/sound/beep-07.wav";
    audio.load();
    audio.play();
  }


}
