import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TypeLogComponent } from './pages/type-log/type-log.component';
import { LoginComponent } from './pages/login/login.component';
import { ListRoomComponent } from './pages/list-room/list-room.component';
import { AuthGuardService } from './services';
import { DeactivateGuard } from './pages/type-log/deactivate-guard';
import { P404Component } from './pages/error/404.component';
import { BangphimGuard, PrivilegeGuard } from './services/privilege.guard';
import { RevoiceComponent } from './pages/revoice/main/revoice.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'rooms',
    pathMatch: 'full',
  },

  {
    path: 'rooms',
    canActivate: [AuthGuardService, PrivilegeGuard],
    component: ListRoomComponent,
  },
  {
    path: 'type',
    canActivate: [AuthGuardService, BangphimGuard],
    canDeactivate: [DeactivateGuard],
    component: TypeLogComponent,
  },
  {
    path: 'revoice/:roomId',
    canActivate: [AuthGuardService],
    component: RevoiceComponent
  },
  {
    path: 'error',
    component: P404Component
  },
  {
    path: '**',
    redirectTo: 'rooms',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
