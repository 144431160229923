<div class="card-body list-body text-left mt-2" [ngClass]="this.bodyPasteBoard">

  <div class="row mb-2">
    <div class="col-12" [ngClass]="this.header">
      รายการ Pasteboard


      <button class="btn btn-info btn-sm " data-toggle="modal" data-target="#exampleModal" data-backdrop="static"
        data-keyboard="false" (click)="openModal(true)">
        เพิ่ม
      </button>
    </div>
  </div>

  <div class="row" style="cursor: pointer;">
    <div class="col-12">
      <input placeholder="ค้นหา Pasteboard..." class=" form-control" [ngClass]="this.searchbar"
        [(ngModel)]="this.shortKeyService.searchText" (input)="searchPasteboardSuggest($event)"
        (click)="searchPasteboard()">
    </div>
  </div>


  <div class="row mt-4">
    <div class="col-12 mb-1" [ngClass]="this.header">
      ผลลัพธ์การค้นหา
    </div>

  </div>
  <div class="row">
    <div class="col-12" *ngFor="let item of this.shortKeyService.short_key_suggest; let i = index;"
      style="cursor: pointer;">
      <div class="text-left form-control" [ngClass]="this.pasteboardValue" style="width: 100%;" data-toggle="tooltip"
        data-placement="top" title="{{item}}" (click)="clickPasteboardSuggest(item)">
        {{item}}

      </div>
    </div>
  </div>



  <div class="row mt-3">
    <div class="col-12 mb-1" [ngClass]="this.header">
      รายการ Pasteboard
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <cdk-virtual-scroll-viewport itemSize="38" [ngClass]="this.scroll">
        <div class="text-left form-control"
          *cdkVirtualFor="let item of this.shortKeyService.pasteboardItem; let i = index;"
          [ngClass]="this.pasteboardValue" title="{{item}}" (click)="clickPasteboard(item)" style="cursor: pointer;">

          {{item}}
        </div>

      </cdk-virtual-scroll-viewport>
    </div>
  </div>



</div>





<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">เพิ่ม Pasteboard</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModal(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <textarea class="form-control" [(ngModel)]="storePasteboardValue" rows="20"
            style="font-size: 1vw; resize: vertical;"></textarea>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="clearPasteboard()">รีเซ็ต</button>
        <button type="button" class="btn btn-primary" (click)="savePasteboard()" data-dismiss="modal">บันทึก</button>
      </div>
    </div>
  </div>
</div>