import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  join_room = false;
  constructor(private http: HttpClient, private oauthService: OAuthService) { }

  login(data) {
    return new Promise((res, rej) => {
      this.http.post(environment.API_URL + '/users/login', data).subscribe(item => {
        res(item);
        localStorage.setItem('bangphimUser', JSON.stringify(item));
      }, err => {
        rej(err.error.message);
      });
    });
  }


  setUserAuth(user) {
    localStorage.setItem('bangphimUser', JSON.stringify(user));
  }

  logout() {
    localStorage.removeItem('bangphimUser');
    this.oauthService.logOut();
  }

  getUser() {
    return JSON.parse(localStorage.getItem('bangphimUser'));
  }

  getToken() {
    return 'Bearer ' + JSON.parse(localStorage.getItem('bangphimUser')).token;
  }

  setJoinRoom(value) {
    this.join_room = value;
  }

  getJoinRoom() {
    return this.join_room;
  }

}
