import { Component, OnInit } from '@angular/core';
import { BroadcastService, AuthService, RoomsService } from '../../services';
import { SuggestionService } from "../../services/suggestion.service";
import { ShortKeyService } from "../../services/short-key.service";

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent implements OnInit {

  bodySuggest = 'sg-body-short-key-pe';
  headSuggest = 'sg-head-short-key-pe';
  valueSuggest = 'sg-value-short-key-pe';
  user;

  constructor(private authService: AuthService,
    public suggestionService: SuggestionService,
    public shortKeyService: ShortKeyService,
    private bcService: BroadcastService,
  ) { }

  ngDestroy() {
    if (this.suggestionService.ws) {
      this.suggestionService.ws.close();
    }
  }

  ngOnInit() {
    this.bodySuggest = 'sg-body-short-key-bp';
    this.headSuggest = 'sg-head-short-key-bp';
    this.valueSuggest = 'sg-value-short-key-bp';
    this.suggestionService.setWordSuggest();
    this.user = this.authService.getUser();
  }


  clickSuggestion(data) {
    this.clickSuggestionBP(data)
  }

  clickSuggestionBP(data) {
    if (data.Tag !== -1) {
      this.shortKeyService.log = this.shortKeyService.log.slice(0, (this.shortKeyService.log.length) - data.Key.length) + data.Value;
      this.suggestionService.setWordSuggest();
      this.sendSuggestText(data.Value, data.Tag);
      for (let index = 0; index < data.Key.length; index++) {
        const msg: any = {
          agentId: this.user.id,
          msgId: this.shortKeyService.option_message.current_message,
          msg: 'Ց'
        };
        this.bcService.sendMsg(JSON.stringify({ action: 'stream', value: msg }));
      }
      const msg: any = {
        agentId: this.user.id,
        msgId: this.shortKeyService.option_message.current_message,
        msg: data.Value,
      };
      this.bcService.sendMsg(JSON.stringify({ action: 'stream', value: msg }));
      // this.shortKeyService.resetPasteBoard();
    }
  }

  sendSuggestText(text, tags) {
    let data;
    if (tags !== -1) {
      data = {
        agentId: this.user.id,
        msgId: this.shortKeyService.option_message.current_message,
        tag: tags,
        isComplete: false,
        msg: text,
      };
    } else {
      data = {
        isComplete: true,
      };
    }
    this.suggestionService.ws.send(JSON.stringify(data));

  }

}
