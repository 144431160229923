import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeGuard  {
  constructor(private router: Router, private authService:AuthService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authService.getUser()
    var roles: String[] = user.realm_access.roles;
    if (roles.includes('postedit') || roles.includes('transcribe')) {
      return true;
    }
    this.router.navigate(['/error'])
    return false;
  }
  
}

@Injectable({
  providedIn: 'root'
})
export class BangphimGuard  {
  constructor(private router: Router, private authService:AuthService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authService.getUser()
    var roles: String[] = user.realm_access.roles;
    if (roles.includes('transcribe')) {
      return true;
    }
    this.router.navigate(['/error'])
    return false;
  }
  
}

@Injectable({
  providedIn: 'root'
})
export class PostEditGuard  {
  constructor(private router: Router, private authService:AuthService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authService.getUser()
    var roles: String[] = user.realm_access.roles;
    if (roles.includes('postedit')) {
      return true;
    }
    this.router.navigate(['/error'])
    return false;
  }
  
}