import { Injectable } from '@angular/core';
import JSZip from 'jszip';
@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  exportVoiceClient(listBufferByte: Float32Array[][], filename: string) {
    return new Promise((resolve, reject) => {
      var zip = new JSZip();
      listBufferByte.forEach((bufferVoice: Float32Array[], listIndex) => {
        bufferVoice.forEach((data: Float32Array, index: number) => {

          const wavBytes = this.getWavBytes(data.buffer, {
            isFloat: true,       // floating point or 16-bit integer
            numChannels: 1,
            sampleRate: 16000,
          })
          const wav = new Blob([wavBytes], { type: 'audio/wav' })
          zip.file(`ช่วงที่${listIndex + 1}/${index + 1}.wav`, wav, { binary: true });
        });
      })
      zip.generateAsync({ type: 'blob' }).then((content) => {
        this.downloadZipFile(content, `${filename}_voice_client.zip`).then((result: boolean) => {
          resolve(true)
        })
      });


    })
  }

  downloadZipFile(content: Blob, filename: string) {
    return new Promise((resolve, reject) => {
      let File;
      let downloadLink;
      File = new Blob([content]);
      downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(File);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setTimeout(() => resolve(true), 500)
    })
  }

  getWavBytes(buffer: any, options: any) {
    const type = options.isFloat ? Float32Array : Uint16Array
    const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT

    const headerBytes = this.getWavHeader(Object.assign({}, options, { numFrames }))
    const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

    // prepend header, then add pcmBytes
    wavBytes.set(headerBytes, 0)
    wavBytes.set(new Uint8Array(buffer), headerBytes.length)

    return wavBytes
  }
  getWavHeader(options: any) {
    const numFrames = options.numFrames
    const numChannels = options.numChannels || 2
    const sampleRate = options.sampleRate || 44100
    const bytesPerSample = options.isFloat ? 4 : 2
    const format = options.isFloat ? 3 : 1

    const blockAlign = numChannels * bytesPerSample
    const byteRate = sampleRate * blockAlign
    const dataSize = numFrames * blockAlign

    const buffer = new ArrayBuffer(44)
    const dv = new DataView(buffer)

    let p = 0

    function writeString(s: any) {
      for (let i = 0; i < s.length; i++) {
        dv.setUint8(p + i, s.charCodeAt(i))
      }
      p += s.length
    }

    function writeUint32(d: any) {
      dv.setUint32(p, d, true)
      p += 4
    }

    function writeUint16(d: any) {
      dv.setUint16(p, d, true)
      p += 2
    }

    writeString('RIFF')              // ChunkID
    writeUint32(dataSize + 36)       // ChunkSize
    writeString('WAVE')              // Format
    writeString('fmt ')              // Subchunk1ID
    writeUint32(16)                  // Subchunk1Size
    writeUint16(format)              // AudioFormat https://i.stack.imgur.com/BuSmb.png
    writeUint16(numChannels)         // NumChannels
    writeUint32(sampleRate)          // SampleRate
    writeUint32(byteRate)            // ByteRate
    writeUint16(blockAlign)          // BlockAlign
    writeUint16(bytesPerSample * 8)  // BitsPerSample
    writeString('data')              // Subchunk2ID
    writeUint32(dataSize)            // Subchunk2Size

    return new Uint8Array(buffer)
  }
}
