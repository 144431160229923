import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ResponseEvent } from '../../../interfaces/event';
import { Room } from '../../../interfaces/room';
import { RoomsService } from '../../../services';

@Component({
  selector: 'revoice-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['../revoice.component.css']
})
export class StreamComponent implements OnInit, OnDestroy, OnChanges {
  @Input() room: Room
  @Input() connectWebRTC: boolean;
  @Output() webRTCConnected = new EventEmitter();
  soundStream: boolean = true;
  @Input() streamMode: string = 'video';
  @Input() trickerMuteSound: boolean;
  connectivity
  timeStream = {
    hour: 0,
    minute: 0,
    sec: 0,
  }

  intervalCountTime;
  isStream
  socket;
  pc;
  web_rtc_status = 'disconnected';
  active_audio = false;

  constructor(private roomService: RoomsService) { }

  ngOnDestroy(): void {
    this.clearIntervalTimeStream();

    if (this.socket) {
      this.socket.close();
    }
    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.room) {
      this.connectivity = this.room.connectivity;
      this.getEventBroadcaster()
    }
    if (this.connectWebRTC && !this.socket) {
      this.connectWsRTC();
    }
    if (this.trickerMuteSound) {
      this.openSoundStream()
    } else {
      this.muteSoundStream()
    }
  }

  getEventBroadcaster() {
    this.roomService.getEventRoom(this.room.id).subscribe((res: ResponseEvent) => {
      const listEventBoardCaster = res.rows.filter(element => element.event == 'publish' || element.event == 'unpublish').sort((a, b) => b.at - a.at);
      if (listEventBoardCaster.length != 0) {
        var lastPublish = listEventBoardCaster.find(element => element.event == 'publish');
        if (lastPublish) {
          this.timeStream = this.calculateTimeStream(lastPublish.at);
        }
      }

    })
  }

  openSoundStream() {
    this.soundStream = true;
    (<HTMLVideoElement>document.getElementById('audioStream')).volume = 1.0;
  }

  muteSoundStream() {
    this.soundStream = false;
    (<HTMLVideoElement>document.getElementById('audioStream')).volume = 0;
  }



  clearIntervalTimeStream() {
    if (this.intervalCountTime) {
      clearInterval(this.intervalCountTime);
    }
  }

  intervalTimeStream() {
    this.intervalCountTime = setInterval(() => {
      this.timeStream.sec++;
      if (this.timeStream.sec == 60) {
        this.timeStream.minute += 1;
        this.timeStream.sec = 0;
      }

      if (this.timeStream.minute == 60) {
        this.timeStream.hour += 1;
        this.timeStream.minute = 0;
      }

    }, 1000);
  }

  calculateTimeStream(time) {
    var publicTime = new Date(time);
    var currentTime = new Date();
    var timeDiff = Math.abs(currentTime.getTime() - publicTime.getTime());
    var tmpMinute = timeDiff % (1000 * 60 * 60);
    var tmpSec = tmpMinute % (1000 * 60);
    return {
      hour: Math.floor(timeDiff / (1000 * 60 * 60)),
      minute: Math.floor(tmpMinute / (1000 * 60)),
      sec: Math.floor(tmpSec / 1000)
    }
  }


  checkTwoNumber(number) {
    return number < 10 ? `0${number}` : number;
  }

  // Connect RTC
  connectWsRTC() {
    this.socket = new WebSocket(`wss://${this.connectivity.host}:${this.connectivity.webrtc_proxy_port}/transcription`);
    this.socket.onopen = () => {
      console.debug('connectWsRTC');
      this.createSession();

    };
    this.socket.onclose = (e) => {
      const log = 'disconnected from server';
      console.debug(log);
    };
    this.socket.onmessage = (e) => {
      const signaling = JSON.parse(e.data);

      if (signaling.id === 'publishStream') {
        this.refreshSound();
      }
      if (signaling.id === 'viewerResponse') {
        this.processAnswer(signaling);

      } else if (signaling.id === 'iceCandidate') {
        // this.createSession();
        this.pc.addIceCandidate(signaling.candidate);
      } else if (signaling.id === 'status') {
        console.log(`error while joining '${signaling.code}' - '${signaling.message}'`);
      } else if (signaling.id === 'unpublishStream') {
        this.isStream = false
        this.clearIntervalTimeStream();

      }
    };
    this.socket.onerror = (e) => {
      console.debug(e);
    };
  }


  processAnswer(signaling) {
    if (signaling.response !== 'accepted') {
      // todo Reconnected
    } else {
      this.playBeef();
      this.isStream = true
      this.intervalTimeStream();
      this.pc.setRemoteDescription(new RTCSessionDescription({
        type: 'answer',
        sdp: signaling.sdpAnswer
      }));

      this.webRTCConnected.emit()

    }
  }

  createSession() {
    this.pc = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:203.183.172.196:3478'
        }
      ]
    });
    this.pc.addTransceiver('video', { direction: 'recvonly' });
    this.pc.addTransceiver('audio', { direction: 'recvonly' });
    this.pc.oniceconnectionstatechange = e => {
      this.web_rtc_status = this.pc.iceConnectionState;
    };
    this.pc.onicecandidate = event => {
      if (event.candidate != null) {
        const iceSignaling = JSON.stringify({
          id: 'iceCandidate',
          candidate: event.candidate
        });
        this.socket.send(iceSignaling);
      }
    };

    this.pc.ontrack = (event) => {
      const el = document.getElementById('videoStream') as HTMLVideoElement;
      el.srcObject = event.streams[0];
      el.autoplay = true;
      el.controls = false;
      el.muted = true;
      el.play();

      const ael = document.getElementById('audioStream') as HTMLVideoElement;
      ael.srcObject = event.streams[0];
      ael.autoplay = true;
      ael.controls = false;
      ael.play();
    };
    this.createOffer('viewer');
  }

  createOffer(signalingType) {
    this.pc.createOffer({ offerToReceiveVideo: true, offerToReceiveAudio: true }).then(sdpOffer => {
      this.pc.setLocalDescription(sdpOffer);
      const offerSignaling = JSON.stringify({
        id: signalingType,
        sdpOffer: sdpOffer.sdp
      });
      this.socket.send(offerSignaling);
    }).catch(err => console.debug(err));
  }

  refreshSound() {
    if (!this.active_audio) {
      this.pc.close();
      this.pc = null;
      this.active_audio = true;
      this.clearIntervalTimeStream()
      this.createSession();
      setTimeout(() => {
        this.active_audio = false;
      }, 5000);
    }
  }


  playBeef() {
    (<HTMLAudioElement>document.getElementById('beep')).play();
  }
}
