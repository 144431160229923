import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Room } from '../../../interfaces/room';

@Component({
  selector: 'log-transribe',
  templateUrl: './log-transribe.component.html',
  styleUrls: ['../revoice.component.css']
})
export class LogTransribeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() room: Room

  resultRevoice: string = '';
  socketResult;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.room) {
      this.recieveResultTranscribe()
    }
  }

  ngOnDestroy(): void {
    if (this.socketResult) {
      this.socketResult.close();
    }
  }

  recieveResultTranscribe() {
    this.socketResult = new WebSocket(`wss://${this.room.connectivity.host}:${this.room.connectivity.revoice_transcribe_port}`);
    this.socketResult.onopen = (e) => {
      console.debug("open text stream");
    };
    this.socketResult.onmessage = (event) => {
      if (event.data) {
        var msg = JSON.parse(event.data);
        this.resultRevoice = msg.result.message_interim;
      }
    };

    this.socketResult.onclose = (event) => {
      console.debug(event)
    };

    this.socketResult.onerror = (error) => {
      console.debug(error);
    };
  }
}
