import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { Team } from '../../../interfaces/team';
import { AuthService } from '../../../services';

@Component({
  selector: 'app-online-user',
  templateUrl: './online-user.component.html',
  styleUrls: ['./online-user.component.css']
})
export class OnlineUserComponent implements OnInit, OnChanges {
  @Input() listAllTeam: Team[];
  @Input() onlineAgent: any[];
  @Input() statusConnect: string;
  @Input() statusRequest: string;
  @Input() activeAgent;
  @Input() timeoutSwitcth;
  @Output() requestSwitchUser = new EventEmitter<number>();
  @Output() confirmSwitchUser = new EventEmitter<boolean>();
  onlineCountTmp = 0;
  auth;
  onlineCount = 0;
  waitingCountAgent = 0;
  currentStatus = 'OFFLINE'
  statusAgnet = ['ACTIVE', 'WAITING', 'BUSY', 'OFFLINE']
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.auth = this.authService.getUser();
  }

  ngOnChanges(): void {

    this.onlineCount = this.onlineAgent.filter(e => e.status != 'OFFLINE').length
    this.waitingCountAgent = this.onlineAgent.filter(e => e.status == 'WAITING').length
    if (this.onlineCount > this.onlineCountTmp) {
      this.playAgentJoin()
    } else if (this.onlineCount < this.onlineCountTmp) {
      this.playAgentLeave()
    }

    if (this.auth && this.activeAgent) {
      this.currentStatus = this.statusAgnet[this.activeAgent[this.auth.ref_id].value]
    }
    this.onlineCountTmp = this.onlineCount

  }

  playAccept() {
    (<HTMLAudioElement>document.getElementById('accept')).play();
  }

  playReject() {
    (<HTMLAudioElement>document.getElementById('reject')).play();
  }

  playAgentJoin() {
    (<HTMLAudioElement>document.getElementById('join')).play();
  }

  playAgentLeave() {
    (<HTMLAudioElement>document.getElementById('leave')).play();
  }

  checkUserOnline(userId) {
    if (this.auth.ref_id == userId) {
      return true;
    }
    return false;
  }

  checkStatusOnline(userId) {
    var user = this.onlineAgent.find(e => e.id == userId);
    if (user) {
      return user.status.toLowerCase();
    } else {
      return 'offline'
    }
  }

  acceptSwitchUser(value: boolean) {
    this.confirmSwitchUser.emit(value)
    if (value) {
      this.playAccept()
    } else {
      this.playReject()
    }
  }


  switchUser(value?) {
    if (this.waitingCountAgent != 0) {
      this.requestSwitchUser.emit(value)
    }
  }


  playAgentOffline() {
    (<HTMLAudioElement>document.getElementById('offline')).play();
  }

  playAgentBusy() {
    (<HTMLAudioElement>document.getElementById('busy')).play();
  }
  playItsYou() {
    (<HTMLAudioElement>document.getElementById('you')).play();
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.currentStatus == 'ACTIVE') {
      if (event.altKey) {
        if (event.keyCode == 83) {
          this.switchUser()
        } else {
          var charCode = (typeof event.which == "undefined") ? event.keyCode : event.which;
          var charStr = String.fromCharCode(charCode);
          if (/\d/.test(charStr)) {
            var numberIndex = parseInt(charStr)
            if (numberIndex <= this.onlineAgent.length) {
              if (this.onlineAgent[numberIndex - 1].id == this.auth.ref_id) {
                this.playItsYou()
              } else {
                switch (this.onlineAgent[numberIndex - 1].status) {
                  case 'OFFLINE':
                    this.playAgentOffline()
                    break;
                  case 'BUSY':
                    this.playAgentBusy()
                    break;
                  default:
                    this.switchUser(this.onlineAgent[numberIndex - 1].id)
                    break;
                }
              }
            }
          }
        }
        event.preventDefault()
      }
    } else if (this.currentStatus == 'WAITING') {
      if (event.altKey) {
        if (event.keyCode == 90 && this.statusRequest == 'accept') {
          this.acceptSwitchUser(true)
        } else if (event.keyCode == 88 && this.statusRequest == 'accept') {
          this.acceptSwitchUser(false)
        }
      }
    }
  }
}
