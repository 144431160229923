
import { TypeLogComponent } from './type-log.component';
import { Injectable } from "@angular/core";
import { RevoiceComponent } from '../revoice/main/revoice.component';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class DeactivateGuard  {

  canDeactivate(component: TypeLogComponent) {
    return component.canDeactivate();
  }
}

// export class DeactivateRevoiceGuard implements CanDeactivate<RevoiceComponent> {

//   canDeactivate(component: RevoiceComponent): Observable<boolean>|Promise<any>|boolean {
//     return component.canDeactivate();
//   }
// }