<nav class="navbar navbar-dark bg-dark">
  <form class="form-inline">
    <input class="form-control mr-sm-2" type="search" placeholder="ชื่อห้อง" aria-label="Search" name="seacrh" [(ngModel)]="seacrh" (search)="submitSearch()">
    <button class="btn btn-outline-success my-2 my-sm-0" type="button" (click)="submitSearch()">ค้นหา</button>
    <div class="navbar-brand my-2 my-sm-0 user" >{{this.user.name}}</div>
  </form>
  <span class="navbar-brand mb-0 h1 out" (click)="logout()">ออกจากระบบ</span>
</nav>
<div class="box">
  <table class="table table-striped fixed_header" >
    <thead>
      <tr>
        <th scope="col" width="5%">ลำดับ</th>
        <th scope="col" width="10%">หมายเลขห้อง</th>
        <th scope="col" width="1000">ชื่อห้องสนทนา</th>
        <th scope="col" width="1000">รายละเอียด</th>
        <th scope="col" width="15%">วันที่</th>
        <th scope="col" width="15%">เวลา</th>
        <th scope="col" width="10%">ประเภทห้อง</th>
        <th scope="col" width="5%">สถานะ</th>
        <th scope="col" width="15%">เมนู</th>
      </tr>
    </thead>
    <tbody *ngIf="list_room.length !== 0 else roomValid">
      <tr *ngFor="let room of list_room; let i = index;">
        <th scope="row" width="5%">{{i+1}}</th>
        <td class="text-center" width="10%">{{room.id}}</td>
        <td width="1000">{{room.title}}</td>
        <td width="1000">{{room.description}}</td>
        <td class="text-center" width="10%">{{room.time.start_time | date:'dd/MM/yyyy'}} -
          {{room.time.end_time | date:'dd/MM/yyyy'}}</td>
        <td class="text-center" width="10%">{{room.time.start_time | date:'HH:mm:ss'}} - {{room.time.end_time | date:'HH:mm:ss'}}
        </td>
        <td class="text-center" width="10%">
          <span *ngIf="room.type == 1" class="badge badge-pill badge-stream">{{findTypeRoom(room.type)}}</span>
          <span *ngIf="room.type == 6" class="badge badge-pill badge-revoice" >{{findTypeRoom(room.type)}}</span>
          <span *ngIf="!(room.type == 1)&&!(room.type == 6)&&(room.type!=null)" class="badge badge-pill badge-other">{{findTypeRoom(room.type)}}</span>
          <span *ngIf="room.type == null" class="badge badge-pill badge-nontype" >{{findTypeRoom(room.type)}}</span>
          
          </td>
          <td class="text-center" width="5%">
            <span *ngIf="room.status <= 3" class="badge badge-pill badge-wait">{{room.text_status || 'ไม่ระบุ'}}</span>
            <span *ngIf="room.status == 4" class="badge badge-pill badge-streaming" >{{room.text_status || 'ไม่ระบุ'}}</span>
            <span *ngIf="room.status >= 5 && room.status < 10" class="badge badge-pill badge-end">{{room.text_status || 'ไม่ระบุ'}}</span>
            <span *ngIf="room.status >= 10" class="badge badge-pill badge-other" >{{room.text_status || 'ไม่ระบุ'}}</span>
          </td>
        <td class="text-center" width="15%">
           <button class="btn btn-primary" (click)="postedit(room)">
              Post Edit
            </button>
            &nbsp;
          <button *ngIf="checkPrivilegeTranscribe(room)" class="btn btn-primary"  (click)="joinRoom(room)">
            เข้าห้อง
          </button>

          <button *ngIf="!checkPrivilegeTranscribe(room)" class="btn btn-secondary" disabled title="ท่านไม่มีสิทธิ์ถอดความห้องสทนานี้" data-bs-toggle="tooltip" data-bs-placement="top" >
            เข้าห้อง
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-template #roomValid>
    <tbody>
      <tr>
        <th colspan="10" style="color: red; background-color:white" width="10000">ไม่พบข้อมูล</th>
      </tr>
    </tbody>
  </ng-template>
</div>