<div class="card text-center border-{{statusConnect}}" role="text" role="heading" aria-level="2">
  <div class="card-body dp-flex flex-center" *ngIf="statusConnect=='connected'">
    <span class="material-icons fs3em  fc-green" aria-hidden="true">supervisor_account</span>&nbsp;<b
      class="fc-green fs1-5em"> {{onlineCount}} online</b>
  </div>
  <div class="card-body dp-flex flex-center" *ngIf="statusConnect=='connect_error'">
    <span class="material-icons fs3em  fc-red" aria-hidden="true">cloud_off</span>&nbsp;<b
      class="fc-red fs1-5em">Connect Error</b>
  </div>
  <div class="card-body dp-flex flex-center" *ngIf="statusConnect=='connecting'">
    <div class="lds-ripple" aria-hidden="true">
      <div class="dot-center"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>&nbsp;
    <b class="fc-yellow fs1-5em">Connecting</b>

  </div>
</div>
<div class="mt-2">
  <div class="row">
    <div class="col-12">
      <button *ngIf="statusRequest == 'none'" type="button" class="btn btn-primary btn-block dp-flex p-3 flex-center"
        (click)="switchUser()" [disabled]="waitingCountAgent == 0 ||  currentStatus != 'ACTIVE'"
        aria-label="เปลี่ยนผู้พูดทวน"><span class="material-icons fs2em" aria-hidden="true">change_circle</span>&nbsp;
        <b aria-hidden="true">เปลี่ยนผู้พูดทวน</b></button>
      <button *ngIf="statusRequest == 'request'" type="button" disabled
        class="btn btn-warning btn-block dp-flex p-3 flex-center"><span class="material-icons fs2em animate-rotate"
          aria-hidden="true">autorenew</span>&nbsp; <b>รอผู้พูดทวนยืนยัน</b></button>
      <div class="row" *ngIf="statusRequest == 'accept'">
        <div class="col-6">
          <button type="button" class="btn btn-success btn-block dp-flex p-3 flex-center"
            (click)="acceptSwitchUser(true)"><span class="material-icons fs2em" aria-hidden="true">check</span>&nbsp;
            <b>ยืนยัน</b></button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-danger btn-block dp-flex p-3 flex-center"
            (click)="acceptSwitchUser(false)"><span class="material-icons fs2em" aria-hidden="true">close</span>&nbsp;
            <b>ปฏิเสธ</b> ({{timeoutSwitcth}})</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-2">
  <div class="card">
    <div class="card-header dp-flex">
      <span class="material-icons" aria-hidden="true">groups</span>&nbsp;<b role="heading" aria-level="2">ทีม
        {{listAllTeam[0].name}}</b>
    </div>
    <div class="card-body">
      <div *ngFor="let user of listAllTeam[0].users">
        <div class="row user-offline mt-1" [class.user-offline]="checkStatusOnline(user.user_id) == 'offline'"
          [class.isOnline]="checkStatusOnline(user.user_id) == 'waiting'"
          [class.isMute]="checkStatusOnline(user.user_id) == 'busy'"
          [class.isRevoice]="checkStatusOnline(user.user_id) == 'active'">
          <div class="col-8 dp-flex">
            <span class="material-icons" aria-hidden="true"
              *ngIf="checkStatusOnline(user.user_id) == 'waiting' ">person</span>
            <span class="material-icons" aria-hidden="true"
              *ngIf="checkStatusOnline(user.user_id) == 'offline'">person</span>
            <span class="material-icons" aria-hidden="true"
              *ngIf="checkStatusOnline(user.user_id) == 'busy'">voice_over_off</span>
            <span class="material-icons" aria-hidden="true"
              *ngIf="checkStatusOnline(user.user_id) == 'active'">record_voice_over</span>
            <div class="pl-2" role="text" [attr.aria-label]="checkStatusOnline(user.user_id) +' ' +user.firstname">
              <label aria-hidden="true">{{user.firstname}}</label>
            </div>
            &nbsp;<div aria-hidden="true" *ngIf="user.user_id == auth.ref_id">(คุณ)</div>
          </div>
          <div class="col-4 db-flex text-right" aria-hidden="true" *ngIf="checkStatusOnline(auth.ref_id) == 'active'">
            <button class="btn btn-outline-primary btn-sm btn-round"
              *ngIf="checkStatusOnline(user.user_id) == 'waiting'" (click)="switchUser(user.user_id)"
              [disabled]="statusRequest == 'request'">
              เปลี่ยน
            </button>
          </div>
          <hr>
        </div>
      </div>

    </div>
  </div>
</div>

<audio aria-hidden="true" controls id="join" hidden>
  <source src="../../../../assets/sound/discord-join.mp3">
</audio>


<audio aria-hidden="true" controls id="leave" hidden>
  <source src="../../../../assets/sound/discord-leave.mp3">
</audio>


<audio aria-hidden="true" controls id="accept" hidden>
  <source src="../../../../assets/sound/accept.mp3">
</audio>


<audio aria-hidden="true" controls id="reject" hidden>
  <source src="../../../../assets/sound/reject.mp3">
</audio>

<audio aria-hidden="true" controls id="busy" hidden>
  <source src="../../../../assets/sound/busy.mp3">
</audio>


<audio aria-hidden="true" controls id="offline" hidden>
  <source src="../../../../assets/sound/offline.mp3">
</audio>

<audio aria-hidden="true" controls id="you" hidden>
  <source src="../../../../assets/sound/you.mp3">
</audio>