/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./streaming_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.RevoiceStreamingClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.RevoiceStreamingPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RevoiceActivationRequest,
 *   !proto.proto.Status>}
 */
const methodDescriptor_RevoiceStreaming_Activate = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/Activate',
  grpc.web.MethodType.UNARY,
  proto.proto.RevoiceActivationRequest,
  proto.proto.Status,
  /**
   * @param {!proto.proto.RevoiceActivationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Status.deserializeBinary
);


/**
 * @param {!proto.proto.RevoiceActivationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.activate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.RevoiceStreaming/Activate',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_Activate,
      callback);
};


/**
 * @param {!proto.proto.RevoiceActivationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Status>}
 *     Promise that resolves to the response
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.activate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.RevoiceStreaming/Activate',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_Activate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.Agent,
 *   !proto.proto.Status>}
 */
const methodDescriptor_RevoiceStreaming_Deactivate = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/Deactivate',
  grpc.web.MethodType.UNARY,
  proto.proto.Agent,
  proto.proto.Status,
  /**
   * @param {!proto.proto.Agent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Status.deserializeBinary
);


/**
 * @param {!proto.proto.Agent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.deactivate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.RevoiceStreaming/Deactivate',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_Deactivate,
      callback);
};


/**
 * @param {!proto.proto.Agent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Status>}
 *     Promise that resolves to the response
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.deactivate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.RevoiceStreaming/Deactivate',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_Deactivate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RevoiceStreamingRequest,
 *   !proto.proto.RevoiceStreamingResponse>}
 */
const methodDescriptor_RevoiceStreaming_Stream = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/Stream',
  grpc.web.MethodType.UNARY,
  proto.proto.RevoiceStreamingRequest,
  proto.proto.RevoiceStreamingResponse,
  /**
   * @param {!proto.proto.RevoiceStreamingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.RevoiceStreamingResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RevoiceStreamingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.RevoiceStreamingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.RevoiceStreamingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.stream =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.RevoiceStreaming/Stream',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_Stream,
      callback);
};


/**
 * @param {!proto.proto.RevoiceStreamingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.RevoiceStreamingResponse>}
 *     Promise that resolves to the response
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.stream =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.RevoiceStreaming/Stream',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_Stream);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.Agent,
 *   !proto.proto.ClientStatusResponse>}
 */
const methodDescriptor_RevoiceStreaming_ListenClientStatus = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/ListenClientStatus',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.proto.Agent,
  proto.proto.ClientStatusResponse,
  /**
   * @param {!proto.proto.Agent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ClientStatusResponse.deserializeBinary
);


/**
 * @param {!proto.proto.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ClientStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.listenClientStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.RevoiceStreaming/ListenClientStatus',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_ListenClientStatus);
};


/**
 * @param {!proto.proto.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ClientStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.listenClientStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.RevoiceStreaming/ListenClientStatus',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_ListenClientStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.Agent,
 *   !proto.proto.SwitchingRequest>}
 */
const methodDescriptor_RevoiceStreaming_ListenSwitchingRequestedStatus = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/ListenSwitchingRequestedStatus',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.proto.Agent,
  proto.proto.SwitchingRequest,
  /**
   * @param {!proto.proto.Agent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SwitchingRequest.deserializeBinary
);


/**
 * @param {!proto.proto.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.SwitchingRequest>}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.listenSwitchingRequestedStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.RevoiceStreaming/ListenSwitchingRequestedStatus',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_ListenSwitchingRequestedStatus);
};


/**
 * @param {!proto.proto.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.SwitchingRequest>}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.listenSwitchingRequestedStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.RevoiceStreaming/ListenSwitchingRequestedStatus',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_ListenSwitchingRequestedStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.Agent,
 *   !proto.proto.AcceptSwitchingRequest>}
 */
const methodDescriptor_RevoiceStreaming_ListenSwitchingResponsedStatus = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/ListenSwitchingResponsedStatus',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.proto.Agent,
  proto.proto.AcceptSwitchingRequest,
  /**
   * @param {!proto.proto.Agent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AcceptSwitchingRequest.deserializeBinary
);


/**
 * @param {!proto.proto.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AcceptSwitchingRequest>}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.listenSwitchingResponsedStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.RevoiceStreaming/ListenSwitchingResponsedStatus',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_ListenSwitchingResponsedStatus);
};


/**
 * @param {!proto.proto.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AcceptSwitchingRequest>}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.listenSwitchingResponsedStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.RevoiceStreaming/ListenSwitchingResponsedStatus',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_ListenSwitchingResponsedStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SwitchingRequest,
 *   !proto.proto.Agent>}
 */
const methodDescriptor_RevoiceStreaming_RequestSwitching = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/RequestSwitching',
  grpc.web.MethodType.UNARY,
  proto.proto.SwitchingRequest,
  proto.proto.Agent,
  /**
   * @param {!proto.proto.SwitchingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Agent.deserializeBinary
);


/**
 * @param {!proto.proto.SwitchingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Agent)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Agent>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.requestSwitching =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.RevoiceStreaming/RequestSwitching',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_RequestSwitching,
      callback);
};


/**
 * @param {!proto.proto.SwitchingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Agent>}
 *     Promise that resolves to the response
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.requestSwitching =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.RevoiceStreaming/RequestSwitching',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_RequestSwitching);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AcceptSwitchingRequest,
 *   !proto.proto.Status>}
 */
const methodDescriptor_RevoiceStreaming_AcceptSwitching = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/AcceptSwitching',
  grpc.web.MethodType.UNARY,
  proto.proto.AcceptSwitchingRequest,
  proto.proto.Status,
  /**
   * @param {!proto.proto.AcceptSwitchingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Status.deserializeBinary
);


/**
 * @param {!proto.proto.AcceptSwitchingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.acceptSwitching =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.RevoiceStreaming/AcceptSwitching',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_AcceptSwitching,
      callback);
};


/**
 * @param {!proto.proto.AcceptSwitchingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Status>}
 *     Promise that resolves to the response
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.acceptSwitching =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.RevoiceStreaming/AcceptSwitching',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_AcceptSwitching);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.Status,
 *   !proto.proto.Status>}
 */
const methodDescriptor_RevoiceStreaming_UpdateStatus = new grpc.web.MethodDescriptor(
  '/proto.RevoiceStreaming/UpdateStatus',
  grpc.web.MethodType.UNARY,
  proto.proto.Status,
  proto.proto.Status,
  /**
   * @param {!proto.proto.Status} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Status.deserializeBinary
);


/**
 * @param {!proto.proto.Status} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.RevoiceStreamingClient.prototype.updateStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.RevoiceStreaming/UpdateStatus',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_UpdateStatus,
      callback);
};


/**
 * @param {!proto.proto.Status} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Status>}
 *     Promise that resolves to the response
 */
proto.proto.RevoiceStreamingPromiseClient.prototype.updateStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.RevoiceStreaming/UpdateStatus',
      request,
      metadata || {},
      methodDescriptor_RevoiceStreaming_UpdateStatus);
};


module.exports = proto.proto;

