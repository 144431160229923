import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  templateUrl: '404.component.html',
  styleUrls: ['404.component.css']
})
export class P404Component {

  constructor(private authService:AuthService) { }

  logout() {
    this.authService.logout();
  }
}
