<router-outlet></router-outlet>
<!-- <div class="card">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <b>Server Time: </b>{{time}}<br>
        <b>Local Time: </b>{{localtime}} <br>
        <b>Offset: </b>{{offset}}<br>
        <b>Request Time: </b>{{REQUEST_TIME}}<br>
      </div>
    </div>
  </div>
</div> -->