<div class="bg-image"></div>
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div class="card-group"  *ngIf="!loading">
            <div class="card p-4">
              <div class="card-body">
                <form (ngSubmit)="login(data)" #data="ngForm">
                  <h1>ลงชื่อเข้าใช้</h1>
                  <p class="text-muted">กรุณากรอกชื่อผู้ใช้และรหัสผ่าน</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-user"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="ชื่อผู้ใช้" name="username" ngModel required>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="รหัสผ่าน" name="password" ngModel required>
                  </div>
                  <div class="row" *ngIf="err_login">
                      <div class="col-12 text-center">
                        <font color="red"><small>{{err_text}}</small></font>
                      </div>
                    </div>
                    <hr>
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary px-4"><i class="fas fa-sign-in-alt"></i>&nbsp;เข้าสู่ระบบ</button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="btn btn-link px-0" (click)="forgetPassword()">ลืมรหัสผ่าน?</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>   
                <div class="card-body text-center white"  *ngIf="loading">
                    <div class="spinner-grow" role="status" >
                        <span class="sr-only">Loading...</span>
                    </div>
                    กรุณารอสักครู่
                </div>
        </div>
      </div>
    </div>
  </main>

