export const environment = {
    production: false,
    API_URL: 'https://api.sandbox.rtt.in.th/v3',
    API_SHELTER: 'https://api.sandbox.rtt.in.th/shelter/v2',
    OAUTH: {
        URL: 'https://accounts.sandbox.rtt.in.th/auth/realms/rtt',
        CLIENT_ID: 'bangphim',
        RESONSE_TYPE: 'code'
    },
    version: 'version 2.4.19 - sandbox - 1',
    PE_URL: 'https://postedit.sandbox.rtt.in.th/'

};
