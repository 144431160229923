import { Component, OnInit } from '@angular/core';
import { AuthService, RoomsService, WebsocketService } from '../../services';
import { Router } from '@angular/router';
import { ListStatus, Status } from '../../interfaces/status';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-list-room',
  templateUrl: './list-room.component.html',
  styleUrls: ['./list-room.component.css']
})
export class ListRoomComponent implements OnInit {
  user: any;
  list_room = [];
  tmp_list_room = [];
  seacrh = '';
  listTypeRoom = [];
  listStatus: Status[] = [];
  constructor(
    private authService: AuthService,
    private roomService: RoomsService,
    private router: Router,
    private ws: WebsocketService) { }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.getTypeRoom();
  }

  getTypeRoom() {
    this.roomService.getTypeRoom().subscribe((res: any) => {
      this.listTypeRoom = res['result'];
      this.getStatusRooms();
    })
  }

  getStatusRooms() {
    this.roomService.getStatusRoom().subscribe((res: ListStatus) => {
      this.listStatus = res.result;
      this.listStatus = this.listStatus.map((status: Status) => {
        status.short = status.value.split(' ')[0];
        return status;
      });
      this.listRooms(this.user.id)
    })
  }
  listRooms(id: any) {
    this.roomService.listRoomsByUser(id).subscribe(({ result }) => {
      this.list_room = result.map((element: any) => {
        element.text_status = this.listStatus.find(v => v.id == element.status).short;
        return element
      });

      console.log(this.list_room);
      this.tmp_list_room = result;
    });
  }
  logout() {
    this.authService.logout();
    // this.router.navigate(['login']);
  }

  joinRoom(room: any) {
    if (room.type != 6 && room.type != 8) {
      this.ws.setPort(room.connectivity);
      this.router.navigate(['type'], { queryParams: { roomId: room.id } });
    } else {
      this.router.navigate(['revoice', room.id]);
    }
    this.authService.setJoinRoom(true);

  }

  postedit(room: any) {
    window.open(environment.PE_URL + '#/?roomId=' + room.id, "_self");
  }

  submitSearch() {
    this.list_room = this.tmp_list_room;
    if (!this.seacrh) {
      this.list_room = this.tmp_list_room;
      return;
    }
    this.list_room = this.tmp_list_room.filter((v: any) => {
      if ((String(v.title)) && String(this.seacrh)) {
        if (String(v.title).toLowerCase().indexOf(String(this.seacrh).toLowerCase()) > -1) {
          return true;
        }
        return false;
      }
      return false;
    });

  }

  searchInput(value) {
    this.seacrh = value
  }

  findTypeRoom(id) {
    var data = this.listTypeRoom.find(e => e.id == id)
    if (data) {
      return data.value;
    }
    return 'ไม่ระบุ';
  }

  checkPrivilegeTranscribe(room) {
    var isPrivilege = false;
    room.teams.forEach(element => {
      var check = element.users.find(user => user.user_id == this.user.id)
      if (check) {
        isPrivilege = true;
      }
    });
    return isPrivilege
  }

}
