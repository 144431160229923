import { Component, OnInit } from "@angular/core";
import {
  BroadcastService,
} from "../../services";
import { ShortKeyService } from "../../services/short-key.service";
import { SuggestionService } from '../../services/suggestion.service';

declare const $: any;

@Component({
  selector: "app-paste-borad",
  templateUrl: "./paste-borad.component.html",
  styleUrls: ["./paste-borad.component.css"],
})
export class PasteBoradComponent implements OnInit {

  public pasteboardItem = [];

  public storePasteboardValue = "";
  public tempPasteboardValue = "";

  public open_modal;
  public search;

  pasteboardValue = "pasteboard-bp";
  bodyPasteBoard = "body-short-key-bp";
  headPasteboard = "head-short-key-bp";
  scroll = "scroll-short-key-bp";
  header = "header-bp";
  headBoard = "";
  searchbar = "searchbar-bp";

  constructor(
    private bcService: BroadcastService,
    public shortKeyService: ShortKeyService,
    private suggestionSevice: SuggestionService
  ) { }

  ngOnInit() {
    this.header = "header-bp";
    this.pasteboardValue = "pasteboard-bp";
    this.bodyPasteBoard = "body-pasteboard-bp";
    this.headPasteboard = "head-pasteboard-bp";
    this.headBoard = "";
    this.scroll = "scroll-pasteboard-bp";
    this.searchbar = "searchbar-bp";


    this.bcService.openWs();
    if (localStorage.getItem('pasteboard')) {
      this.setPasteboard();
    }
  }

  searchPasteboard() {
    this.shortKeyService.search = true;
  }

  clickPasteboard(value) {
    this.shortKeyService.clickshortkeysBP(value);
  }

  clickPasteboardSuggest(value) {
    this.shortKeyService.pasteboardSuggestBP(value);
  }

  searchPasteboardSuggest(event) {
    this.shortKeyService.searchPasteboardFromBar(this.shortKeyService.searchText);
  }

  savePasteboard() {
    $(document).ready(() => {
      $('[data-toggle="tooltip"]').tooltip("hide");
    });
    this.saveToLocalStorage(this.storePasteboardValue)

    this.pasteboardItem = this.storePasteboardValue
      .split("\n")
      .filter((item) => item);
    this.shortKeyService.pasteboardItem = this.pasteboardItem;
    this.tempPasteboardValue = this.storePasteboardValue;
    this.openModal(false);

    $(document).ready(() => {
      $('[data-toggle="tooltip"]').tooltip({});
    });


    let pasteboard = {
      pasteboard: this.pasteboardItem
    }

    this.suggestionSevice.wsaddword.send(JSON.stringify(pasteboard))

  }

  saveToLocalStorage(input) {
    localStorage.setItem('pasteboard', input)
  }

  setPasteboard() {
    this.storePasteboardValue = localStorage.getItem('pasteboard');
    this.pasteboardItem = this.storePasteboardValue
      .split("\n")
      .filter((item) => item);
    this.shortKeyService.pasteboardItem = this.pasteboardItem;
    this.tempPasteboardValue = this.storePasteboardValue;
    this.openModal(false);
    $(document).ready(() => {
      $('[data-toggle="tooltip"]').tooltip({});
    });
  }

  openModal(value) {
    this.open_modal = value;
    this.shortKeyService.open_modal = value;
    this.storePasteboardValue = this.tempPasteboardValue;
  }

  clearPasteboard() {
    this.storePasteboardValue = "";
  }

}
