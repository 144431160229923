import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  timeout = 14;
  forceoutStatus = true;
  newlineStatus = false;
  lastlineEditStatus = false;
  audioDelayStatus = true;
  delay = 4;
  audioContext = new AudioContext();
  audioDelay
  fontSize = 35;
  constructor() {
    this.audioDelay = this.audioContext.createDelay(30);
  }

  setTimeDelay(second: Number) {
    this.audioDelay.delayTime.value = second;
  }

  changeFontSize(event) {
    if (this.fontSize > 100) {
      this.fontSize = 100;
    }
    if (this.fontSize < 20) {
      this.fontSize = 20;
    }
    let q1 = document.getElementsByClassName('ql-container') as HTMLCollectionOf<HTMLElement>;
    q1[0].style.fontSize = this.fontSize + 'px'
  }

}
