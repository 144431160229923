import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { RevoiceSocketService } from './revoice-socket.service';
import { WebsocketService } from './websocket.service';
@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  message: Subject<any>;
  RTC: Subject<any>;

  reSocket: Subject<any>;
  constructor(private wsService: WebsocketService, private revoiceSocketService: RevoiceSocketService) {

  }

  openWs() {
    this.message = this.wsService.connect();
  }

  sendMsg(msg) {
    this.message.next(msg);
  }


  sendMsgRTC(msg) {
    this.RTC.next(msg);
  }

  openRevoiceSocket() {
    this.reSocket = this.revoiceSocketService.revoiceConnect()
  }

  sendRevoiceSocket(msg) {
    this.reSocket.next(msg);
  }
}
