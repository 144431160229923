import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService {

  constructor(public router: Router, private authService: AuthService, private oauthService: OAuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.getUser() != null && this.oauthService.getIdentityClaims()) {
      return true;
    }

    // not logged in so redirect to login page with the return url

    this.oauthService.logOut();
    return false;
  }
}
