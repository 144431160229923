<nav class="navbar navbar-dark bg-dark">
  <div class="form-inline">
    <label *ngIf="room">
      <span class="navbar-brand mb-0 h1" role="heading" aria-level="3">({{room.id || '-'}}) {{room.title || '-'}}</span>
    </label>
  </div>
  <span aria- role="button" role="button" class="navbar-brand mb-0 h1 out" (click)="back()">
    สิ้นสุดการถอดความ</span>
</nav>

<div class="row " *ngIf="room">
  <div class="col-3 box-left">
    <div class="row">
      <div class="col-12">
        <div class="card dp-flex flex-center h-100">
          <div class="card-body text-center">
            <label class="fs1-5em" role="heading" aria-level="2"><b>สถานะของคุณ</b></label> <br>
            <b class="status-text status-{{checkCurrentStatus()}}"> {{checkCurrentStatus()}} </b>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" aria-hidden="true">
      <div class="col-12">
        <div class="card dp-flex flex-center voice-detect-card">
          <div class="card-body text-center">
            <!-- <label class="fs1-5em">ตรวจจับเสียง:</label>  -->
            <!-- &nbsp; -->
            <div id="voiceDetectIcon" class="circle-voice-detect mt-3">
              <span class="material-icons-outlined">
                mic
              </span>

            </div><br><br>
            <span id="countActive" class="mb-4 mt-4" style="font-size: 1.5em;">เวลาพูด 0 วินาที</span><br>
            <span class="mt-5">กดปุ่ม [ <i class="fa fa-keyboard"></i> Enter ] เพื่อหยุด</span>
          </div>

        </div>
      </div>
    </div>


    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-12 text-center">
                <b role="heading" aria-level="2">พร้อมพูดทวน</b>
              </div>
            </div>

            <div class="row" role="tablist">

              <div class="offset-lg-1 col-lg-5  text-center">
                <button type="button" role="tab"
                  [attr.aria-selected]="(activeAgent && (activeAgent[profile.id].value == 1 || activeAgent[profile.id].value == 0))"
                  class="btn btn-sm dp-flex flex-center btn-round  w-100p"
                  [disabled]="!activeAgent|| (activeAgent && activeAgent[profile.id].value == 0)"
                  [class.btn-outline-secondary]=" (activeAgent && (activeAgent[profile.id].value != 1 && activeAgent[profile.id].value != 0))"
                  [class.btn-primary]="(activeAgent && (activeAgent[profile.id].value == 1 || activeAgent[profile.id].value == 0))"
                  (click)="openMic()"><span class="material-icons" aria-hidden="true">person</span> &nbsp;
                  พร้อม</button>

              </div>
              <div class="col-lg-5  text-center">
                <button type="button" role="tab"
                  [attr.aria-selected]="(activeAgent && activeAgent[profile.id].value == 2)"
                  class="btn btn-sm dp-flex flex-center btn-round  w-100p"
                  [disabled]="!activeAgent|| (activeAgent && activeAgent[profile.id].value == 0)"
                  [class.btn-outline-secondary]=" (activeAgent && activeAgent[profile.id].value != 2)"
                  [class.btn-danger]=" (activeAgent && activeAgent[profile.id].value == 2)" (click)="muteMic()"><span
                    class="material-icons" aria-hidden="true">voice_over_off</span> &nbsp; ไม่พร้อม</button>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-5 dp-flex flex-center">
                <b>แสดงข้อความ:</b>
              </div>
              <div class="col-md-7 text-center dp-flex flex-left">
                <button type="button" class="btn dp-flex flex-center btn-round"
                [class.btn-outline-secondary]="!resultTranscribe" 
                [class.btn-primary]="resultTranscribe" (click)="resultTranscribe = true"><span class="material-icons">
                  closed_caption
                  </span>&nbsp; แสดง</button>
                &nbsp;
                <button type="button" class="btn dp-flex flex-center btn-round"
                [class.btn-outline-secondary]="resultTranscribe" 
                [class.btn-primary]="!resultTranscribe" (click)="resultTranscribe = false"><span class="material-icons">
                  closed_caption_disabled
                  </span> &nbsp; ไม่แสดง</button>
  
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div> -->


    <!-- <hr  aria-hidden="true"> -->
    <div class="row mt-3" aria-hidden="true">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-12 text-center">
                <b>รูปแบบการแสดงผล</b>
              </div>
            </div>
            <div class="row">

              <div class=" offset-lg-1 col-lg-5">
                <button type="button" class="btn btn-sm dp-flex flex-center btn-round w-100p"
                  [class.btn-outline-secondary]="streamMode != 'video'" [class.btn-primary]="streamMode == 'video'"
                  (click)="changeVideoMode()"><span class="material-icons">videocam</span> &nbsp; Video</button>

              </div>
              <div class="col-lg-5">
                <button type="button" class="btn btn-sm dp-flex flex-center btn-round w-100p"
                  [class.btn-outline-secondary]="streamMode != 'audio'" [class.btn-primary]="streamMode == 'audio'"
                  (click)="changeAudioMode()"><span class="material-icons">audiotrack</span> &nbsp; Audio</button>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-12 text-center">
                <b role="heading" aria-level="2">เปิด/ปิดเสียง</b>
              </div>
            </div>
            <div class="row" role="tablist">
              <div class="offset-lg-1 col-lg-5 ">

                <button type="button" class="btn btn-sm dp-flex flex-center btn-round w-100p" role="tab"
                  [attr.aria-selected]="soundStream" [class.btn-outline-secondary]="!soundStream"
                  [class.btn-primary]="soundStream" (click)="soundStream = true"><span class="material-icons"
                    aria-hidden="true">volume_up</span> &nbsp; เปิด</button>




              </div>
              <div class="col-lg-5">
                <button type="button" class="btn btn-sm dp-flex flex-center btn-round w-100p"
                  [class.btn-outline-secondary]="soundStream" role="tab" [attr.aria-selected]="!soundStream"
                  [class.btn-primary]="!soundStream" (click)="soundStream = false"><span class="material-icons"
                    aria-hidden="true">volume_off</span> &nbsp; ปิด</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row mt-3">
      <div class="col-12 ">
        <button type="button" class="btn btn-warning btn-block text-left dp-flex p-3 flex-center"
        [class.active]="active_audio"
        [disabled]="active_audio"
        (click)="refreshSound()"
        ><span class="material-icons">loop</span>&nbsp; <b>REFRESH AUDIO</b></button>
      </div>
    </div> -->
  </div>
  <div class="col-7 box-center">
    <revoice-stream [connectWebRTC]="actionConnectWebRTC" [room]="room" [streamMode]="streamMode"
      [trickerMuteSound]="soundStream" (webRTCConnected)="startVADWasm()"></revoice-stream>
    <div class="mt-3" *ngIf="resultTranscribe">
      <log-transribe [room]="room">
      </log-transribe>


    </div>
  </div>

  <div class="col-2 box-right">
    <app-online-user [listAllTeam]="room.teams" [onlineAgent]="onlineAgents" [statusConnect]="statusConnect"
      [statusRequest]="statusRequest" [activeAgent]="activeAgent" [timeoutSwitcth]="timeoutSwitcth"
      (confirmSwitchUser)="acceptSwitchUser($event)" (requestSwitchUser)="switchUser($event)"></app-online-user>
  </div>
</div>



<div class="modal fade" id="settingModal" tabindex="-1" role="dialog" aria-labelledby="settingModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="settingModalLabel">ตั้งค่า</h5>
        <button type="button" class="close" data-dismiss="modal" arisa-label="Close" (click)="closeSetting()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">ปุ่มกดเพื่อพูด</label>
            <div class="col-sm-10">
              <div class="input-group mb-2">

                <input name="key" type="text" class="form-control text-center" readonly [ngModel]="tmpKeyToTalk.code"
                  [class.record-active]="isRecordKeyBind">
                <div class="input-group-prepend text-center">
                  <div class="input-group-text text-center wrap" (click)="recordKeybind()" *ngIf="!isRecordKeyBind">
                    <span class="material-icons old">
                      keyboard
                    </span>
                    <span class="new">
                      Edit Keybind
                    </span>
                  </div>

                  <div class="input-group-text text-center wrap2 record-active" (click)="recordKeybind()"
                    *ngIf="isRecordKeyBind">
                    <span>
                      Recording..
                    </span>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveSetting()">บันทึก</button>
      </div>
    </div>
  </div>
</div>


<audio aria-hidden="true" controls id="request" hidden>
  <source src="../../../../assets/sound/request.wav">
</audio>

<audio aria-hidden="true" controls id="switching" hidden>
  <source src="../../../../assets/sound/switching.mp3">
</audio>

<audio aria-hidden="true" controls id="reject" hidden>
  <source src="../../../../assets/sound/reject.mp3">
</audio>