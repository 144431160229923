import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {


  modelEdit: any = {
    onColor: 'primary',
    offColor: 'secondary',
    onText: '',
    offText: '',
    disabled: false,
    size: '',
    value: false
  };

  modelForceout: any = {
    onColor: 'primary',
    offColor: 'secondary',
    onText: '',
    offText: '',
    disabled: true,
    size: '',
    value: true
  };

  modelNewline: any = {
    onColor: 'primary',
    offColor: 'secondary',
    onText: '',
    offText: '',
    disabled: false,
    size: '',
    value: false
  };
  modelAudioDelay: any = {
    onColor: 'primary',
    offColor: 'secondary',
    onText: '',
    offText: '',
    disabled: true,
    size: '',
    value: true
  }

  toggleStatus = 'ปิดการแก้ไขเทิร์นล่าสุด';
  toggleForceoutStatus = 'ปรับเวลา Force-out';
  toggleNewlineStatus = 'เปิดการเว้นบรรทัด';
  toggleAudioDelayStatus = 'เปิดการ delay เสียง';


  constructor(public settingService:SettingService) { }


  ngOnInit(): void {
  }

  toggleChange(event) {
    if (event) {
      this.toggleStatus = 'เปิดการแก้ไขเทิร์นล่าสุด';
      this.settingService.lastlineEditStatus = true;
    } else {
      this.toggleStatus = 'ปิดการแก้ไขเทิร์นล่าสุด';
      this.settingService.lastlineEditStatus = false;
    }
  }

  toggleForceout(event) {
    if (event) {
      this.toggleForceoutStatus = 'เปิดการใช้งาน Force-out';
      this.modelForceout.disabled = true;
      this.settingService.forceoutStatus = true;
    } else {
      this.toggleForceoutStatus = 'เปิดการใช้งาน Force-out';
      this.modelForceout.disabled = false;
      this.settingService.forceoutStatus = false;
    }
  }


  toggleNewline(event) {
    if (event) {
      this.toggleNewlineStatus = 'เปิดการเว้นบรรทัด';
      this.settingService.newlineStatus = true;
    } else {
      this.toggleNewlineStatus = 'เปิดการเว้นบรรทัด';
      this.settingService.newlineStatus = false;
    }
  }

  toggleAudioDelay(event) {
    if (event) {
      this.toggleAudioDelayStatus = 'เปิดการ delay เสียง';
      this.modelAudioDelay.disabled = true;
      this.settingService.audioDelayStatus = true;
      this.settingService.setTimeDelay(this.settingService.delay);
    } else {
      this.toggleAudioDelayStatus = 'เปิดการ delay เสียง';
      this.modelAudioDelay.disabled = false;
      this.settingService.audioDelayStatus = false;
      this.settingService.setTimeDelay(0);
    }
  }

  setDelay(event) {
    this.settingService.setTimeDelay(parseInt(event.target.value));
  }

}
