<div class="row " [ngClass]="this.bodyShortkey"
  *ngFor="let item of this.shortKeyService.short_key_preview; let i = index;" style="cursor: pointer;">
  <div class="col-md-2 text-center" [ngClass]="this.headShortKey">
    <div class="center-align">F{{i+1}}</div>
  </div>
  <div class="col-md-10 text-left" [ngClass]="this.shortkeyValue" data-toggle="tooltip" data-placement="right"
    title="{{item}}" [class.active]="this.shortKeyService.short_key_active[i]" (click)="clickShortkey(item)"
    id="sk{{i}}">
    <div ellipsis class="center-align">{{item}}</div>
  </div>
</div>