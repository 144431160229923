import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading = false;
  err_login = false;
  err_text = '';
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    if (this.authService.getUser()) {
      this.router.navigate(['rooms']);
    }
  }

  forgetPassword() { }

  login(data: NgForm) {
    this.err_login = false;
    this.loading = true;
    this.authService.login(data.value)
      .then(item => {
        this.err_login = false;
        this.router.navigate(['rooms']);
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        this.err_login = true;
        this.err_text = err;
      });
  }
}
