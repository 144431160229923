import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { BroadcastService, AuthService, RoomsService } from '../../services';
import { ShortKeyService } from '../../services/short-key.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PasteBoradComponent } from '../paste-borad/paste-borad.component';
import { SuggestionService } from '../../services/suggestion.service';
declare const $: any;

@Component({
  selector: 'app-type-log',
  templateUrl: './type-log.component.html',
  styleUrls: ['./type-log.component.css']
})
export class TypeLogComponent implements OnInit, OnDestroy {
  wsSubscription: Subscription;
  wsRTCscription: Subscription;

  log = '';
  socket;
  log_next = '';
  offset;
  pc;
  web_rtc_status = 'disconnected';
  user;
  KEY_SECURE = [
    'Tab',
    'CapsLock',
    'Shift',
    'Control',
    'Meta',
    'Alt',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'ArrowRight',
    'Insert',
    'Delete',
    'PageUp',
    'PageDown',
    'Home',
    'End',
    'NumLock',
    'Unidentified',
    'AudioVolumeMute',
    'AudioVolumeDown',
    'AudioVolumeUp',
    'MediaTrackPrevious',
    'MediaPlayPause',
    'MediaTrackNext',
    'Escape',
    'F11',
    'F12',
    'ContextMenu',
    'Clear',
    'Dead',
    'OS'
  ];
  shortkey = {
    F1: null,
    F2: null,
    F3: null,
    F4: null,
    F5: null,
    F6: null,
    F7: null,
    F8: null,
    F9: null,
    F10: null,
    // F11: null,
    // F12: null
  };

  agent_online = 0;

  option_message = {
    next_message: -1,
    current_message: -1
  };

  forceout_temp = 0;

  message_temp = {
    next_message: 0,
    current_message: -1
  };


  join_room = false;
  readyTime = {
    msgId: -1,
    startTime: '',
    typingDuration: 0,
    waitDuration: 0,
    forceOutTime: 0,
  };
  begin;
  ready;
  start;
  room: any = {
    id: 0,
  };
  load_shortkey = false;
  active_shortkey = false;
  active_audio = false;
  active_switch = false;
  enter_key = true;
  start_progrees_yellow = 0;
  start_progrees_red = 0;

  switch_team = {
    status: '',
    user_id: -1
  };

  network_connection = true;
  connectivity: any;
  constructor(
    private bcService: BroadcastService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private roomService: RoomsService,
    private router: Router,
    private connectionService: ConnectionService,
    public shortKeyService: ShortKeyService,
    private modalService: NgbModal,
    private pasteboard: PasteBoradComponent,
    private suggestionService: SuggestionService) {
  }


  ngOnDestroy() {
    this.authService.setJoinRoom(false);
    if (this.wsSubscription) {
      this.wsSubscription.unsubscribe();
    }
    clearTimeout(this.ready);
    clearTimeout(this.start);
    clearTimeout(this.begin);
    if (this.socket) {
      this.socket.close();
    }
    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }
    window.location.reload();
  }

  ngOnInit() {
    this.connectionService.monitor().subscribe((online: any) => {
      this.network_connection = online;
      if (online) {
        this.bcService.openWs();
        this.wsConnect();
        this.joinRoom(this.user.id);
      } else {
        this.wsSubscription.unsubscribe();
        this.bcService.sendMsg(JSON.stringify({ action: 'closeWs', value: {} }));
      }
    });

    this.suggestionService.setWordSuggest();
    document.addEventListener('contextmenu', event => {
      event.preventDefault();
    }, false);

    this.route.queryParams.subscribe(param => {
      if (this.authService.getJoinRoom()) {
        this.roomService.getRoomById(param['roomId']).subscribe((items: any) => {
          this.shortkey = {
            F1: items.short_keys[0] || '',
            F2: items.short_keys[1] || '',
            F3: items.short_keys[2] || '',
            F4: items.short_keys[3] || '',
            F5: items.short_keys[4] || '',
            F6: items.short_keys[5] || '',
            F7: items.short_keys[6] || '',
            F8: items.short_keys[7] || '',
            F9: items.short_keys[8] || '',
            F10: items.short_keys[9] || '',
          };
          $(document).ready(() => {
            $('[data-toggle="tooltip"]').tooltip({});
          });
          this.connectivity = items.connectivity;
          console.debug(this.connectivity);
          this.room = items;
          this.calTime();
          this.user = this.authService.getUser();
          this.bcService.openWs();
          this.wsConnect();
          this.joinRoom(this.user.id);
          this.connectWsRTC();
          this.suggestionService.connectSuggestion(
            items.connectivity.host,
            items.connectivity.suggestion_port
          );
          this.suggestionService.addword(
            items.connectivity.host,
            items.connectivity.suggestion_port)
        });
      } else {
        this.router.navigate(['/rooms']);
      }

    });
    let dropzoneId = 'log';
    window.addEventListener("dragenter", (e: any) => {
      if (e.target.id != dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
    }, false);

    window.addEventListener("dragover", (e: any) => {
      if (e.target.id != dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
    });
    window.addEventListener("drop", (e: any) => {
      if (e.target.id !== dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
    });
  }

  joinRoom(id) {
    const msg = {
      action: 'joinRoom',
      value: {
        userId: id,
      }
    };
    this.bcService.sendMsg(JSON.stringify(msg));
  }

  calTime() {
    document.getElementById('Start').style.width = '0';
    document.getElementById('Start').style.transition = 'width .6s linear';
    document.getElementById('Ready').style.width = '0';
    document.getElementById('Ready').style.transition = 'width .6s linear';
    this.offset = 0;
  }
  startFirst() {
    this.shortKeyService.disabled_text = false;
    (<HTMLAudioElement>document.getElementById('beep')).play();
    document.getElementById('Start').style.width = '0';
    document.getElementById('Start').style.transition = `width ${this.readyTime.typingDuration * 1000}ms linear`;
    document.getElementById('Start').style.width = '100%';
    this.calStart();
  }

  calStart() {
    this.start = setTimeout(() => {
      this.enter_key = false;
      (<HTMLAudioElement>document.getElementById('beep')).play();
      document.getElementById('Start').style.transition = 'none';
      document.getElementById('Start').style.width = '0';
    }, (this.readyTime.typingDuration + 0.03) * 1000);
  }

  readyFirst() {
    document.getElementById('Ready').style.width = '0';
    document.getElementById('Ready').style.width = '80%';
    document.getElementById('Ready2').style.width = '0%';
    document.getElementById('Ready').style.transition = `width ${(this.start_progrees_yellow) * 1000}ms linear`;
    this.calReady();
  }
  calReady() {
    this.ready = setTimeout(() => {
      this.setForceOut();
      document.getElementById('Ready2').style.width = '20%';
      document.getElementById('Ready2').style.transition = `width ${1000}ms linear`;
      setTimeout(() => {
        document.getElementById('Ready').style.transition = 'none';
        document.getElementById('Ready2').style.transition = 'none';
        document.getElementById('Ready').style.width = '0';
        document.getElementById('Ready2').style.width = '0';
      }, this.start_progrees_red);

    }, ((this.start_progrees_yellow)) * 1000);
  }

  onEnter() {
    if (!this.enter_key) {
      this.shortKeyService.log = '';
    }
  }

  clicksk(event, id) {
    event.preventDefault();
    this.shortKeyService.functionShortkeysBP(id);
  }

  confirmExit() {
    const modal = document.getElementById('myModal');
    modal.style.display = 'block';
    const modelContent = document.getElementById('modelContent');

  }

  disableInput() {
    this.shortKeyService.search = false;
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.shortKeyService.open_modal || this.shortKeyService.search) {
      return;
    } else {
      const indexs = Object.keys(this.shortkey).findIndex((element) => element === event.key);
      const value = Object.values(this.shortkey)[indexs];
      const msg: any = {
        agentId: this.user.id,
        msgId: this.shortKeyService.option_message.current_message,
        msg: '',
      };
      if (event.key === 'PageDown') {
        this.active_shortkey = true;
        this.refreshShortKey();
        setTimeout(() => {
          this.active_shortkey = false;
        }, 200);
        event.preventDefault();
      } else if (event.key === 'End') {
        this.refreshSound();
        event.preventDefault();
      }

      // พิมพ์ข้อวคาม
      if (!this.shortKeyService.disabled_text) {
        document.getElementById('log').focus();
        (document.getElementById('log') as HTMLTextAreaElement).setSelectionRange(this.shortKeyService.log.length, this.shortKeyService.log.length);
        if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) {
          return;
        }
        if (event.key === 'Backspace') {
          // ลบทีละตัว
          if (this.shortKeyService.log) {
            msg.msg = 'Ց';
            this.sendSuggestText('Ց', 0);
          } else {
            event.preventDefault();
            return;
          }
        } else if (event.key === 'Enter') {
          if (!this.enter_key) {
            this.enter_key = true;
            this.shortKeyService.disabled_text = true;
            this.shortKeyService.log = '';
            this.shortKeyService.resetPasteBoard();
            msg.isComplete = true;
            this.sendSuggestText('', -1);
          } else {
            event.preventDefault();
            return;
          }
          document.getElementById('log').blur();
        } else if (event.key === 'Insert') {
          const word = this.suggestionService.word_suggestion.find(v => v.Label === 'Insert');
          this.clickSuggestion(word);
          msg.msg = word.Value;
        } else if (event.key === 'Home') {
          const word = this.suggestionService.word_suggestion.find(v => v.Label === 'Home');
          this.clickSuggestion(word);
          msg.msg = word.Value;
        } else if (event.key === 'PageUp') {
          const word = this.suggestionService.word_suggestion.find(v => v.Label === 'PgUp');
          this.clickSuggestion(word);
          msg.msg = word.Value;
        } else {
          if (value !== undefined) {
            switch (event.key) {
              case 'F1': this.clicksk(event, 'sk0'); break;
              case 'F2': this.clicksk(event, 'sk1'); break;
              case 'F3': this.clicksk(event, 'sk2'); break;
              case 'F4': this.clicksk(event, 'sk3'); break;
              case 'F5': this.clicksk(event, 'sk4'); break;
              case 'F6': this.clicksk(event, 'sk5'); break;
              case 'F7': this.clicksk(event, 'sk6'); break;
              case 'F8': this.clicksk(event, 'sk7'); break;
              case 'F9': this.clicksk(event, 'sk8'); break;
              case 'F10': this.clicksk(event, 'sk9'); break;
            }
            event.preventDefault();
          } else {
            if (this.KEY_SECURE.includes(event.key)) {
              event.preventDefault();
              return;
            } else {
              msg.msg = event.key;
              this.sendSuggestText(msg.msg, 0);
            }
          }
        }
        this.bcService.sendMsg(JSON.stringify({ action: 'stream', value: msg }));
      } else {
        event.preventDefault();
      }
    }
  }



  wsConnect() {
    this.wsSubscription = this.bcService.message.subscribe(msg => {
      if (msg.action === 'joinRoom') {
        this.join_room = true;
        this.agent_online = msg.value.onlineAgent;
        // console.debug('================', msg);
      }
      if (this.join_room) {
        if (msg.action === 'readyTime') {
          this.message_temp = {
            next_message: msg.value.msgId + 1,
            current_message: msg.value.msgId
          };
          this.readyTime = msg.value;
          const wait_start = Number(this.readyTime.startTime) - (new Date().getTime() + this.offset);
          this.begin = setTimeout(() => {
            this.log_next = '';
            this.shortKeyService.option_message = this.message_temp;
            this.forceout_temp = msg.value.forceOutTime;
            this.startFirst();
          }, wait_start);
          const wait_ready = (Number(this.readyTime.startTime) - (this.readyTime.waitDuration * 1000)) - (new Date().getTime() + this.offset);
          if (wait_ready > 0) {
            setTimeout(() => {
              if (this.shortKeyService.option_message.current_message !== -1) {
                this.start_progrees_yellow = (this.forceout_temp - new Date().getTime()) / 1000;
                this.start_progrees_red = Number(this.readyTime.startTime) - this.forceout_temp + 30;
                // console.debug(this.start_progrees_yellow);
                if (this.start_progrees_yellow < 0) {
                  this.setProgressLineDefault();
                }
              } else {
                this.setProgressLineDefault();
              }
              this.readyFirst();
            }, wait_ready);
          } else {
            if (this.shortKeyService.option_message.current_message !== -1) {
              this.start_progrees_yellow = (this.forceout_temp - new Date().getTime()) / 1000;
              this.start_progrees_red = Number(this.readyTime.startTime) - this.forceout_temp + 30;
              // console.debug(this.start_progrees_yellow);
              if (this.start_progrees_yellow < 0) {
                this.setProgressLineDefault();
              }
            } else {
              this.setProgressLineDefault();
            }
            this.readyFirst();
          }
        }
        if (msg.action === 'streamBroadcaster') {
          if (msg.value.msgId === this.shortKeyService.option_message.next_message) {
            if (msg.value.msgText[0] === 'Ց') {
              this.log_next = this.log_next.slice(0, (this.log_next.length) - msg.value.msgText.length);
            } else {
              this.log_next += msg.value.msgText;
            }
          }
        }
        if (msg.action === 'forceOut') {
          // this.setForceOut();

          if (this.shortKeyService.searchText == '') {
            this.pasteboard.shortKeyService.resetPasteBoard();
          }

        }
        if (msg.action === 'respondSwitch') {
          // console.debug('================', msg);
          this.switch_team = msg.value;
          if (this.switch_team.status === 'accept') {
            setTimeout(() => {
              this.switch_team.status = '';
            }, 2000);
          }
        }
      }
    });
  }


  setProgressLineDefault() {
    this.start_progrees_yellow = ((Number(this.readyTime.startTime) - new Date().getTime()) / 1000) - 1;
    this.start_progrees_red = 1030;
  }


  setForceOut() {
    document.getElementById('log').blur();
    this.enter_key = true;
    this.shortKeyService.disabled_text = true;
    this.shortKeyService.log = '';
    this.sendSuggestText('', -1);
    this.suggestionService.setWordSuggest();
  }

  back() {
    this.router.navigate(['rooms']);
  }

  canDeactivate() {
    return confirm("ต้องการจะออกจากหน้านี้หรือไม่?");
  }


  connectWsRTC() {
    console.debug('connectWsRTC');
    this.socket = new WebSocket(`wss://${this.connectivity.host}:${this.connectivity.webrtc_proxy_port}/transcription`);
    this.socket.onopen = () => {
      const log = 'connected to server';
      this.createSession();
    };
    this.socket.onclose = (e) => {
      const log = 'disconnected from server';
      console.debug(log);
    };
    this.socket.onmessage = (e) => {
      const signaling = JSON.parse(e.data);
      // console.debug(signaling);
      if (signaling.id === 'publishStream') {
        this.refreshSound();
      }
      if (signaling.id === 'viewerResponse') {
        this.processAnswer(signaling);
      } else if (signaling.id === 'iceCandidate') {
        // this.createSession();
        this.pc.addIceCandidate(signaling.candidate);
      } else if (signaling.id === 'status') {
        console.debug(`error while joining '${signaling.code}' - '${signaling.message}'`);
      }
    };
    this.socket.onerror = (e) => {
      console.debug(e);
    };
  }


  processAnswer(signaling) {
    // console.debug(signaling);
    if (signaling.response !== 'accepted') {
      // todo Reconnected
    } else {
      this.pc.setRemoteDescription(new RTCSessionDescription({
        type: 'answer',
        sdp: signaling.sdpAnswer
      }));
    }
  }

  createSession() {
    this.pc = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:203.183.172.196:3478'
        }
      ]
    });
    this.pc.addTransceiver('video', { direction: 'recvonly' });
    this.pc.addTransceiver('audio', { direction: 'recvonly' });
    this.pc.oniceconnectionstatechange = e => {
      this.web_rtc_status = this.pc.iceConnectionState;
    };
    this.pc.onicecandidate = event => {
      if (event.candidate != null) {
        const iceSignaling = JSON.stringify({
          id: 'iceCandidate',
          candidate: event.candidate
        });
        // console.debug(iceSignaling);
        this.socket.send(iceSignaling);
      }
    };
    this.pc.ontrack = (event) => {
      const el = document.getElementById('video') as HTMLVideoElement;
      el.srcObject = event.streams[0];
      el.autoplay = true;
      el.controls = false;
      el.muted = true;
      el.play();

      const ael = document.getElementById('audio') as HTMLVideoElement;
      ael.srcObject = event.streams[0];
      ael.autoplay = true;
      ael.controls = false;
      ael.play();
    };
    this.createOffer('viewer');
  }

  createOffer(signalingType) {
    this.pc.createOffer({ offerToReceiveVideo: true, offerToReceiveAudio: true }).then(sdpOffer => {
      // console.debug(sdpOffer)
      this.pc.setLocalDescription(sdpOffer);
      const offerSignaling = JSON.stringify({
        id: signalingType,
        sdpOffer: sdpOffer.sdp
      });
      this.socket.send(offerSignaling);
    }).catch(err => console.debug(err));
  }

  refreshSound() {
    if (!this.active_audio) {
      this.pc.close();
      this.pc = null;
      this.active_audio = true;
      this.createSession();
      setTimeout(() => {
        this.active_audio = false;
      }, 5000);
    }

  }
  refreshShortKey() {
    $(document).ready(() => {
      $('[data-toggle="tooltip"]').tooltip('hide');
    });
    this.load_shortkey = true;
    this.shortKeyService.refreshShortKey(this.room.id);
  }


  pasteUrl(text) {
    text.preventDefault();
  }


  switchTeam() {
    this.bcService.sendMsg(JSON.stringify({ action: 'switchTeam', value: { user_id: this.user.id } }));
    this.active_switch = true;
    setTimeout(() => {
      this.active_switch = false;
    }, 2000);
  }


  // Manage Suggestion

  clickSuggestion(data) {
    console.debug(data);
    if (data.Tag !== -1) {
      for (let index = 0; index < data.Key.length; index++) {
        const msg: any = {
          agentId: this.user.id,
          msgId: this.shortKeyService.option_message.current_message,
          msg: 'Ց'
        };
        this.bcService.sendMsg(JSON.stringify({ action: 'stream', value: msg }));
      }
      $(document).ready(() => {
        $('#suggestion').tooltip('hide');
      });
      this.shortKeyService.log = this.shortKeyService.log.slice(0, (this.shortKeyService.log.length) - data.Key.length) + data.Value;
      this.sendSuggestText(data.Value, data.Tag);
    }
  }

  sendSuggestText(text, tags) {
    let data;
    if (tags !== -1) {
      data = {
        agentId: this.user.id,
        msgId: this.shortKeyService.option_message.current_message,
        tag: tags,
        isComplete: false,
        msg: text,
      };
    } else {
      data = {
        isComplete: true
      };
    }
    this.suggestionService.ws.send(JSON.stringify(data)
    );
  }


}
