<div class="bg-black" aria-hidden="true">
  <span class="signal-stream wrap-signal" *ngIf="!isStream">
    <span class="material-icons-outlined short-status-signal">
      wifi_tethering_off
    </span>
    <span class="long-status-signal">&nbsp;No Signal</span>
  </span>
  <span class="signal-stream streaming wrap-signal" *ngIf="isStream">
    <span class="short-status-signal ">
      <div class="lds-ripple">
        <div class="dot-center"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </span>

    <span class="long-status-signal">&nbsp;Streaming</span>
  </span>
  <video class="dp-video" autoplay id="videoStream" [class.audio-mode]="streamMode=='audio'" muted>
  </video>
  <audio id="audioStream" style="display: none;"></audio>
</div>

<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-9 dp-flex alg-center">
        <div class="row ">
          <div class="col">
            <b role="heading" aria-level="2">Streaming Time</b>
          </div>
          <div class="col-auto">
            {{checkTwoNumber(timeStream.hour)}}:{{checkTwoNumber(timeStream.minute)}}:{{checkTwoNumber(timeStream.sec)}}
          </div>
        </div>

      </div>
      <div class="col-md-3 dp-flex jus-con-right">
        <button type="button" class="btn btn-warning  text-left dp-flex flex-center" [class.active]="active_audio"
          [disabled]="active_audio" (click)="refreshSound()"><span class="material-icons">loop</span>&nbsp; <b>REFRESH
            AUDIO</b></button>

      </div>


    </div>

  </div>
</div>

<!-- <div class="row mt-3">
    <div class="col-12 ">
      <button type="button" class="btn btn-warning btn-block text-left dp-flex p-3 flex-center"
      [class.active]="active_audio"
      [disabled]="active_audio"
      (click)="refreshSound()"
      ><span class="material-icons">loop</span>&nbsp; <b>REFRESH AUDIO</b></button>
    </div>
  </div> -->

<!-- <div class="row mt-3">
  <div class="col-6">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-5 dp-flex flex-center">
            <b>รูปแบบการแสดงผล:</b>
          </div>
          <div class="col-md-7 text-center dp-flex flex-left">
            <button type="button" class="btn dp-flex flex-center btn-round" [class.btn-outline-secondary]="streamMode!='video'" [class.btn-primary]="streamMode=='video'" (click)="changeVideoMode()"><span class="material-icons">videocam</span> &nbsp; Video</button>
              &nbsp;
              <button type="button" class="btn dp-flex flex-center btn-round" [class.btn-outline-secondary]="streamMode!=='audio'" [class.btn-primary]="streamMode=='audio'" (click)="changeAudioMode()"><span class="material-icons">audiotrack</span> &nbsp; Audio</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-5 dp-flex flex-center">
            <b>เปิด/ปิดเสียง:</b>
          </div>
          <div class="col-md-7 text-center dp-flex flex-left">
            <button type="button" class="btn dp-flex flex-center btn-round" [class.btn-outline-secondary]="!soundStream" [class.btn-primary]="soundStream" (click)="openSoundStream()"><span class="material-icons">volume_up</span> &nbsp; เปิด</button>
            &nbsp;
            <button type="button" class="btn dp-flex flex-center btn-round" [class.btn-outline-secondary]="soundStream" [class.btn-danger]="!soundStream" (click)="muteSoundStream()"><span class="material-icons">volume_off</span> &nbsp; ปิด</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 -->


<audio aria-hidden="true" controls id="beep" hidden>
  <source src="../../../../assets/sound/beep-07.wav">
</audio>