<span data-toggle="modal" data-target="#myModal" class="material-icons">
  settings
</span>

<!-- Modal -->
<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" style="color: black;">ตั้งค่า Post Edit</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>

      </div>
      <div class="modal-body">
        <!-- <ng-toggle onColor="{{modelEdit.onColor}}" onText="{{modelEdit.onText}}" offColor="{{modelEdit.offColor}}"
            offText="{{modelEdit.offText}}" [size]="modelEdit.size"
            [(ngModel)]="modelEdit.value" class="toggle-button" (valueChange)="toggleChange($event)">
          </ng-toggle> <a class="text-value">{{toggleStatus}}</a>
          <hr><br>  -->
        <div style="padding-left: 65px;" *ngIf="this.settingService.forceoutStatus">
          <!-- <ng-toggle onColor="{{modelForceout.onColor}}" onText="{{modelForceout.onText}}" offColor="{{modelForceout.offColor}}"
            offText="{{modelForceout.offText}}" [size]="modelForceout.size"
            [(ngModel)]="modelForceout.value" class="toggle-button" (valueChange)="toggleForceout($event)">
          </ng-toggle>  -->
          <a class="text-value">{{toggleForceoutStatus}}</a>
          <div *ngIf="this.modelForceout.disabled" class="setting-modal">
            <input type="range" [(ngModel)]="this.settingService.timeout" min="4" max="16">
            <div class="value-suffix">{{this.settingService.timeout}} วินาที</div>
          </div>

        </div>
        <hr *ngIf="this.settingService.forceoutStatus">

        <ng-toggle onColor="{{modelNewline.onColor}}" onText="{{modelNewline.onText}}"
          offColor="{{modelNewline.offColor}}" offText="{{modelNewline.offText}}" [size]="modelNewline.size"
          [(ngModel)]="modelNewline.value" class="toggle-button" (valueChange)="toggleNewline($event)">
        </ng-toggle> <a class="text-value">{{toggleNewlineStatus}}</a>

        <hr>
        <div>
          <ng-toggle onColor="{{modelAudioDelay.onColor}}" onText="{{modelAudioDelay.onText}}"
            offColor="{{modelAudioDelay.offColor}}" offText="{{modelAudioDelay.offText}}" [size]="modelAudioDelay.size"
            [(ngModel)]="modelAudioDelay.value" class="toggle-button" (valueChange)="toggleAudioDelay($event)">
          </ng-toggle> <a class="text-value">{{toggleAudioDelayStatus}}</a>
          <div *ngIf="this.modelAudioDelay.disabled" class="setting-modal">
            <input type="range" [(ngModel)]="this.settingService.delay" (change)="this.setDelay($event)" min="1"
              max="30">
            <div class="value-suffix">{{this.settingService.delay}} วินาที</div>
          </div>

        </div>
        <hr>
        <div>
          <div class="setting-modal text-value">ขนาดตัวอักษร (20 - 100) px</div>
          <input class="value-suffix" type="number" min="20" max="100" [(ngModel)]="this.settingService.fontSize"
            (input)="this.settingService.changeFontSize($event)">
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>